import { Box } from "@mui/system";
import React, { useContext, useEffect, useState } from "react";

import { MainSidebar } from "../../components/MainSidebar/MainSidebar";
import { MainTopbar } from "../MainTopBar/MainTopbar";
import { ContentContainer } from "./ContentContainer";
import api from "../../utils/api";
import { toast } from "react-toastify";
import { AuthContext } from "../../providers/AuthProvider";
import mockNotifications from "../../utils/examples/notifications.json";

export const AuthorizedLayout = ({ children, styles }) => {
  const [mainSidebarVisible, setMainSidebarVisible] = useState(false);
  const [notificationsVisible, setNotificationsVisible] = useState(false);
  const [supportModal, setSupportModal] = useState(false);

  const [loading, setLoading] = useState(true);
  const [notifications, setNotifications] = useState({});

  const { user } = useContext(AuthContext);

  useEffect(() => {
    getNotifications();
  }, []);

  const getNotifications = async () => {
    setLoading(true);
    try {
      // const { data } = await api.get("talents/notifications");
      const { data } = mockNotifications;

      if (data) {
        setNotifications(
          data
            .map((item) => {
              return {
                notificationId: item?.id,
                ...item?.attributes,
              };
            })
            .sort((a, b) => {
              return b.id - a.id;
            })
        );
      }
      return [];
    } catch (error) {
      console.log(error);
      toast.error("Não foi possível carregar a lista de notificações.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box style={{ backgroundColor: "#fff", minHeight: "100vh" }}>
      <MainTopbar
        mainSidebarVisible={mainSidebarVisible}
        setMainSidebarVisible={setMainSidebarVisible}
        notificationsVisible={notificationsVisible}
        setNotificationsVisible={setNotificationsVisible}
        loading={loading}
        notifications={notifications}
      />
      <MainSidebar
        visible={mainSidebarVisible}
        setVisible={setMainSidebarVisible}
        supportModal={supportModal}
        setSupportModal={setSupportModal}
      />

      <ContentContainer
        styles={styles}
        notificationsVisible={notificationsVisible}
        setNotificationsVisible={setNotificationsVisible}
        loading={loading}
        notifications={notifications}
        setNotifications={setNotifications}
      >
        {children}
      </ContentContainer>
    </Box>
  );
};
