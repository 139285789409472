import React from "react";
import { PolarArea } from "react-chartjs-2";
import "chart.js/auto";

const CulturalChart = () => {
  const data = {
    labels: ["Executor", "Idealizador", "Comunicador", "Analítico"],
    datasets: [
      {
        label: "Fit Cultural",
        data: [400, 100, 300, 200],
        backgroundColor: ["#BEFCF9"],
        // borderColor: "#57BAB8",
        borderWidth: 1,
        hoverBackgroundColor: ["#57BAB8"],
      },
    ],
  };

  const config = {
    type: "polarArea",
    data: data,
    options: {
      responsive: true,
      scales: {
        r: {
          pointLabels: {
            display: true,
            centerPointLabels: true,
            color: "#57BAB8",
            font: {
              size: 12,
              weight: 400,
            },
          },
          ticks: {
            display: false,
          },
          grid: {
            display: true,
            // backgroundColor: "red",
          },
        },
      },
      plugins: {
        legend: {
          display: false,
        },
        title: {
          display: false,
        },
        tooltip: {
          enabled: false,
        },
      },
    },
  };

  return (
    <PolarArea data={data} options={config.options} plugins={config.plugins} />
  );
};

export default CulturalChart;
