import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import VideoThumbnail from "./components/VideoThumbnail";
import { Loader } from "../../components/Loader";
import { AuthContext } from "../../providers/AuthProvider";
import astronauta from "../../assets/astronauta-perfil.png";
import astro1 from "../../assets/posesPNG/astronout-standing-ringlight.png";
import astro2 from "../../assets/posesPNG/astronout-sitting-notebook.png";
import astro3 from "../../assets/posesPNG/astronout-standing-sign.png";
import astro4 from "../../assets/posesPNG/astronout-tpose-front.png";
import astro5 from "../../assets/posesPNG/astronout-holding-phone.png";
import astro6 from "../../assets/posesPNG/astronout-standing-headphone.png";
import astro7 from "../../assets/posesPNG/astronout-riding-rocket.png";
import { uploadFile } from "../../utils/aws";
import api from "../../utils/api";

import "./SelfieLetter.css";

const SelfieLetter = () => {
  // const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [videos, setVideos] = useState("");
  const [loading, setLoading] = useState(false);
  const [loadingVideos, setLoadingVideos] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);

  const getVideos = async () => {
    setLoadingVideos(true);
    try {
      const { data } = await api.get(`/talents/selfie_letter`);
      setVideos(data?.data?.attributes?.selfieLetterUrl || "");
      setLoadingVideos(false);
    } catch (error) {
      setLoadingVideos(false);
    }
  };

  const deleteVideos = async () => {
    setLoadingDelete(true);
    try {
      await api.delete(`/talents/selfie_letter/delete`);
      setLoadingDelete(false);
      window.location.reload();
    } catch (error) {
      setLoadingDelete(false);
    }
  };

  const handleVideo = async (event) => {
    const selectedFile = event.target.files[0];
    if (selectedFile) {
      setLoading(true);
      try {
        const upload = await uploadFile(selectedFile);
        console.log(upload);

        if (upload?.file) {
          try {
            await api.post(`/talents/selfie_letter`, {
              data: {
                attributes: {
                  filename: upload?.file.filename,
                  byte_size: upload?.file.byte_size,
                  checksum: upload?.file.checksum,
                  key: upload?.file.key,
                  content_type: upload?.file.content_type,
                  metadata: {
                    user_id: user.id,
                  },
                },
              },
            });
            setLoading(false);
            window.location.reload();
            toast.success("Video enviado com sucesso");
          } catch (error) {
            setLoading(false);
            console.log(error);
            toast.error("Erro ao enviar o video");
          }
        }
      } catch (error) {
        console.error("Erro ao enviar o video:", error);
        toast.error("Erro ao enviar o video");
        setLoading(false);
      }
    }
  };

  const infoPassos = [
    {
      title: "Ambiente é Tudo!",
      content: [
        "Escolha um local tranquilo e bem iluminado para gravar seu vídeo.",
        "Nada de luz fraquinha, hein? E, antes de começar, não se esqueça de verificar se a lente do seu dispositivo está limpinha!",
      ],
      img: astro1,
    },
    {
      title: "Seja você, mas…",
      content: [
        "Prepare um roteiro simples! Pense nos principais pontos que deseja destacar, como suas experiências e suas super habilidades.",
        "Escreva em tópicos apenas para lembrar.",
        "Seja natural, deixa fluir…",
      ],
      img: astro2,
    },
    {
      title: "Sorria, você está... ",
      content: [
        "Olhe diretamente para a bolinha preta e mostre seu entusiasmo!",
        "É estranho ficar olhando fixo para câmera, mas para quem assiste, cria uma conexão, entende?",
        "E o seu sorriso pode realmente te destacar!",
      ],
      img: astro3,
    },
    {
      title: "And the Oscar goes to…",
      content: [
        "Faça alguns ensaios antes de gravar a versão final.",
        "Isso ajuda a ajustar o tom e garantir que você esteja dentro do tempo limite.",
        "Mas não espere ficar perfeito! Você pode voltar aqui e atualizar quando quiser.",
      ],
      img: astro4,
    },
    {
      title: "Deita a tela!",
      content: [
        "Os recrutadores vão ver o seu vídeo em um computador, então, grave na posição horizontal (modo paisagem, 16:9, vira o celular…).",
        "Garanta que você apareça no centro do vídeo e da cintura para cima.",
      ],
      img: astro5,
    },
    {
      title: "Checa tudo!",
      content: [
        "Volume? Tá ouvindo bem? Barulho externo? Algum cachorro latiu por aí? Cortou alguma fala? Passou dos 2 minutos?",
        "Não! Então vamos lá!",
        "Sua Selfie Letter vai ficar incrível com as suas revisões.",
      ],
      img: astro6,
    },
    {
      title: "É só falar bora! Que eu boro!",
      content: [
        "Quando estiver pronto, suba sua Selfie Letter no botão abaixo. E depois?",
        "É só esperar as mensagens dos recrutadores interessados no seu perfil, subir no foguete e decolar para sua próxima jornada!",
      ],
      img: astro7,
    },
  ];

  useEffect(() => {
    getVideos();
  }, []);

  return (
    <AuthorizedLayout>
      {loadingVideos ? (
        <div className="containerLoader">
          <Loader />
        </div>
      ) : (
        <div className="containerSelfieLetter">
          <div className="cardSendVideo">
            <div className="containerText">
              <div className="title">Selfie Letter</div>
              <div className="subtitle1">
                Estamos entusiasmados em conhecer você melhor!
              </div>
              <div className="subtitle2">
                O Selfie Letter é uma oportunidade para você se apresentar de
                maneira autêntica
              </div>

              <label className={`buttonSend ${loading && "loading"}`}>
                {loading
                  ? "Enviando"
                  : videos !== ""
                  ? "Enviar Outro Vídeo"
                  : " Enviar Vídeo"}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleVideo}
                />
              </label>
            </div>
            <img src={astronauta} alt="SelfieLetter icon" />
          </div>

          {videos === "" ? (
            <>
              <div className="titleContemt">
                Siga os passos abaixo para gravar e enviar seu vídeo. Estamos
                ansiosos para ver sua apresentação!
              </div>

              <div className="containerCorousel">
                <div className="corousel">
                  {infoPassos.map((item, key) => (
                    <div className="card" key={key}>
                      <div className="index">{key + 1}</div>

                      <div className="containerImage">
                        <img src={item.img} height={"100%"} alt="astronauta" />
                      </div>

                      <div className="card-body">
                        <div className="title">{item.title}</div>
                        {item.content.map((text, idx) => (
                          <div key={idx} className="subtitle">
                            {text}
                          </div>
                        ))}
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <label className={`buttonSelfie ${loading && "loading"}`}>
                {loading ? "Enviando" : " Enviar Vídeo"}
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={handleVideo}
                />
              </label>
            </>
          ) : (
            <>
              <div>
                <VideoThumbnail videoUrl={videos} />
              </div>

              <div
                className={`buttonRemove ${loadingDelete && "loading"}`}
                onClick={() => deleteVideos()}
              >
                Remover Vídeo
              </div>
            </>
          )}
        </div>
      )}
    </AuthorizedLayout>
  );
};

export default SelfieLetter;
