import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../../components/AuthorizedLayout";
import { Loader } from "../../../components/Loader";
import { AuthContext } from "../../../providers/AuthProvider";
import astronauta from "../../../assets/image 16.png";
import astro2 from "../../../assets/image 15.png";
import api from "../../../utils/api";

import "./Screen2.css";

const Screen2 = ({ setCurrentScreen }) => {
  // const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const [questions, setQuestions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingQuestions, setLoadingQuestions] = useState(false);

  const getQuestions = async () => {
    setLoadingQuestions(true);
    try {
      const { data } = await api.get(`/questions/cultural_fit_questions`);
      const mapQuestions = data?.data.map((item) => ({
        id: item.id,
        ...item.attributes,
      }));
      setQuestions(mapQuestions);
      setLoadingQuestions(false);
    } catch (error) {
      setLoadingQuestions(false);
    }
  };

  const getQuestions2 = async () => {
    try {
      const { data } = await api.get(`/questions/questions`);
    } catch (error) {}
  };

  useEffect(() => {
    getQuestions();
    getQuestions2();
  }, []);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState({});
  const [completeQuestions, setCompleteQuestions] = useState(false);

  const handleOptionChange = (questionId, optionId) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: optionId,
    }));
  };

  const handleNext = () => {
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      handleSubmit();
    }
  };

  const handlePrevious = () => {
    if (currentQuestionIndex > 0) {
      setCurrentQuestionIndex(currentQuestionIndex - 1);
    }
  };

  const handleSubmit = () => {
    console.log("Respostas salvas:", responses);
    // Aqui você pode enviar as respostas para um servidor ou processá-las conforme necessário
  };

  const currentQuestion = questions[currentQuestionIndex];

  const nextQuestions = async () => {
    setLoading(true);
    try {
      await api.post(`/talents/cultural_fit/answer_questions`, {
        data: {
          attributes: {
            questionId: currentQuestionIndex + 1,
            answerOption: responses[currentQuestionIndex + 1],
          },
        },
      });
      if (currentQuestionIndex === 24) {
        setCompleteQuestions(true);
        // setCurrentQuestionIndex(questions.lengt + 1);
      } else {
        handleNext();
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Por favor, selecione uma resposta.");
    }
  };

  return (
    <>
      {loadingQuestions ? (
        <div className="containerLoader">
          <Loader />
        </div>
      ) : (
        <div className="containerScreen2">
          {completeQuestions ? (
            <>
              <div className="bar">
                <div className="fill">
                  <span
                    className="leads"
                    style={{
                      width: `${(
                        (currentQuestionIndex / (questions.length - 1)) *
                        100
                      ).toFixed(2)}%`,
                    }}
                  ></span>
                </div>
              </div>
              <div className="cardCenter2">
                <img src={astronauta} alt="astronauta" />
                <div className="title">
                  É isso ai, seu Fit Cultural está pronto!
                </div>
                <div className="subttile">
                  Obrigado por compartilhar suas perspectivas conosco. Suas
                  respostas nos ajudarão a entender como podemos trabalhar
                  melhor juntos.
                </div>

                <div
                  className={`buttonRemove ${loading && "loading"}`}
                  style={{ fontWeight: "700", width: "90%" }}
                  onClick={() => setCurrentScreen('screen1')}
                >
                  Clique aqui para voltar e visuializar
                </div>
              </div>
            </>
          ) : (
            <>
              <div className="bar">
                <div className="fill">
                  <span
                    className="leads"
                    style={{
                      width: `${(
                        (currentQuestionIndex / (questions.length - 1)) *
                        100
                      ).toFixed(2)}%`,
                    }}
                  ></span>
                </div>
              </div>

              <div className="cardCenter">
                <div className="title">
                  Pergunta {currentQuestion?.questionOrder}
                </div>
                <div className="subttile">{currentQuestion?.questionText}</div>

                {currentQuestion?.options.map((option) => (
                  <label key={option.id} className="containerQuestions">
                    <input
                      type="radio"
                      name={`question-${currentQuestion?.id}`}
                      value={option.option_id}
                      onChange={() =>
                        handleOptionChange(
                          currentQuestion?.id,
                          option.option_id
                        )
                      }
                      checked={
                        responses[currentQuestion?.id] === option.option_id
                      }
                    />

                    <div>{option.option_text}</div>
                  </label>
                ))}

                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  {/* {currentQuestionIndex > 0 && (
            <button type="button" onClick={handlePrevious}>
              Anterior
            </button>
          )}
          <button type="button" onClick={handleNext}>
            {currentQuestionIndex < questions.length - 1
              ? "Próximo"
              : "Finalizar"}
          </button> */}
                  <div
                    className={`buttonRemove ${loading && "loading"}`}
                    style={{ fontWeight: "700" }}
                    onClick={() => nextQuestions()}
                  >
                    {loading ? "Enviando..." : "Responder e continuar"}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Screen2;
