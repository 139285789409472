import React, { useState } from "react";

import iconPLay from "../../../assets/Vector (1).svg";

const VideoPlayer = ({ videoUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlay = () => {
    setIsPlaying(true);
  };

  return (
    <div style={styles.container}>
      {!isPlaying ? (
        <div style={styles.thumbnailContainer} onClick={handlePlay}>
          <video style={styles.image}>
            <source src={videoUrl} />
            Your browser does not support the video tag.
          </video>
          <div style={styles.playButtonContainer}>
            <img src={iconPLay} alt="ssss" />
          </div>
        </div>
      ) : (
        <video controls style={styles.video} autoPlay>
          <source src={videoUrl} />
          Your browser does not support the video tag.
        </video>
      )}
    </div>
  );
};

const styles = {
  container: {
    position: "relative",
    width: "90vw",
    height: "200px",
    borderRadius: "10px",
    overflow: "hidden",
  },
  thumbnailContainer: {
    position: "relative",
    width: "100%",
    height: "100%",
    cursor: "pointer",
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
  },
  videoHidden: {
    display: "none",
  },
  playButtonContainer: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    cursor: 'pointer'
  },
  playButton: {
    width: "60px",
    height: "60px",
  },
  video: {
    width: "100%",
    height: "100%",
  },
};

export default VideoPlayer;
