import React, { useContext, useEffect, useState } from "react";
// import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import { Loader } from "../../components/Loader";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import Screen1 from "./Screen1";
import Screen2 from "./Screen2";

import "./CulturalFIt.css";

const CulturalFIt = () => {
  const [currentScreen, setCurrentScreen] = useState("screen1");

  const renderScreen = () => {
    switch (currentScreen) {
      case "screen2":
        return (<Screen2 setCurrentScreen={setCurrentScreen} />);
      default:
        return (<Screen1 setCurrentScreen={setCurrentScreen} />);
    }
  };

  return (
    <AuthorizedLayout>
      <div className="containerCulturalFIt">
        {renderScreen(currentScreen)}
      </div>
    </AuthorizedLayout>
  );
};

export default CulturalFIt;
