import {
  Button,
  ClickAwayListener,
  Container,
  Grow,
  IconButton,
  Modal,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import React, { useContext, useState } from "react";
import { Loader } from "../../Loader";
import {
  ArrowForward,
  Close,
  Notifications,
  NotificationsOff,
  PersonAdd,
} from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../providers/AuthProvider";
import api from "../../../utils/api";
import useIsMobileDevice from "../../../utils/mobileCheck";
import hexToRgb from "../../../utils/hexToRgba";

import astrounautHoldingPhone from "../../../assets/posesPNG/astronout-holding-phone.png";

export const ContentContainer = ({
  styles,
  children,
  notificationsVisible,
  setNotificationsVisible,
  loading,
  notifications,
  setNotifications,
}) => {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const handleNotificationTitle = (title, name) => {
    switch (title) {
      case "Novo Lead Recebido":
        return (
          <>
            <strong>{name}</strong> agora é sua Lead. Veja o resumo e
            classificação de interesse.
          </>
        );

      default:
        return <>{title}</>;
    }
  };

  const handleClick = async (notification) => {
    try {
      // const data = await api.put(
      //   `/talents/notifications/${notification.notificationId}/readed`
      // );

      setNotifications((currentNotifications) => {
        const updatedNotications = currentNotifications.reduce(
          (acc, currentItem) => {
            if (currentItem.notificationId === notification.notificationId) {
              acc.push({ ...currentItem, readed: true });
            } else {
              acc.push(currentItem);
            }
            return acc;
          },
          []
        );
        return updatedNotications;
      });

      //console.log(data);
    } catch (error) {}
  };

  const readAll = async () => {
    try {
      // const data = await api.put(
      //   `/talents/notifications/${notification.notificationId}/readed`
      // );

      setNotifications((currentNotifications) => {
        const updatedNotications = currentNotifications.reduce(
          (acc, currentItem) => {
            if (currentItem.readed === false) {
              acc.push({ ...currentItem, readed: true });
            } else {
              acc.push(currentItem);
            }
            return acc;
          },
          []
        );
        return updatedNotications;
      });

      //console.log(data);
    } catch (error) {}
  };

  const isMobile = useIsMobileDevice();

  const NotificationCard = ({ notification }) => {
    return (
      <Box
        sx={{
          backgroundColor: "white",
          // borderRadius: "10px",
          // marginBottom: "5px",
          display: "flex",
          minHeight: "50px",
          justifyContent: "space-around",
          alignItems: "center",
          borderBottom: "1px solid #122137",
          width: "100%",
          cursor: "pointer",
          opacity: notification?.readed ? "30%" : "100%",
          ":hover": {
            opacity: notification?.readed ? "45%" : "75%",
            backgroundColor: `${hexToRgb("#57BAB8", 0.15)}`,
          },
          flexDirection: "column",
          paddingTop: "20px",
        }}
        onClick={() => {
          handleClick(notification);
        }}
      >
        {/* <div
          style={{ display: "flex", width: "15%", justifyContent: "center" }}
        >
          {notification.title === "Novo Lead Recebido" ? (
            <PersonAdd color="primary" />
          ) : (
            <Notifications color="primary" />
          )}
        </div> */}
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
          }}
        >
          <Typography fontSize={16} color={"#211E1F"} fontWeight={700}>
            {notification.title}
          </Typography>
        </div>
        <div
          style={{
            display: "flex",
            width: "100%",
            justifyContent: "center",
            marginTop: "10px",
            marginBottom: "10px",
          }}
        >
          <Typography
            fontSize={13}
            textAlign={"justify"}
            fontWeight={400}
            color={"#211E1F"}
          >
            {notification.description}
          </Typography>
        </div>
        {/* {notification.title === "Novo Lead Recebido" ? (
          <div
            style={{ display: "flex", width: "15%", justifyContent: "center" }}
          >
            <ArrowForward color="primary" />
          </div>
        ) : (
          <div
            style={{ display: "flex", width: "15%", justifyContent: "center" }}
          ></div>
        )} */}
      </Box>
    );
  };

  return (
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        // minHeight: "100vh",
        overflow: "auto",
        overflowX: "hidden",
        // paddingTop: 12,
      }}
    >
      {user.userType === "admin" ? null : (
        <Box
          sx={{
            position: "absolute",
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            pointerEvents: "none",
          }}
        >
          <ClickAwayListener
            mouseEvent="onMouseDown"
            onClickAway={() =>
              notificationsVisible ? setNotificationsVisible(false) : null
            }
          >
            <Grow
              in={notificationsVisible}
              style={{ transformOrigin: "right top" }}
              {...(notificationsVisible ? { timeout: 1000 } : {})}
            >
              <Box
                sx={{
                  zIndex: 5,
                  width: !isMobile ? "400px" : "100%",
                  height: !isMobile ? "500px" : "350px",
                  backgroundColor: "#FFFFFF",
                  marginRight: !isMobile ? "25px" : "15px",
                  marginLeft: !isMobile ? null : "15px",
                  marginTop: "15px",
                  borderRadius: "5px",
                  border: "2px solid #57BAB8",
                  boxShadow: 16,
                  overflowY: "auto",
                  scrollbarWidth: "none",
                  pointerEvents: "auto",
                }}
              >
                {loading ? (
                  <Loader size={100} />
                ) : (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "30px",
                    }}
                  >
                    {notifications && notifications?.length > 0 ? (
                      <div>
                        <div
                          style={{
                            paddingBottom: "20px",
                            borderBottom: "1px solid #BFB7B8",
                          }}
                        >
                          <Button
                            color="secondary"
                            sx={{
                              backgroundColor: "white",
                              border: "1px solid #57BAB8",
                              borderRadius: "80px",
                              color: "#57BAB8",
                              fontSize: "13px",
                            }}
                            variant="outlined"
                            style={{ textTransform: "none" }}
                            onClick={() => readAll()}
                          >
                            Marcar todas como lidas
                          </Button>
                        </div>
                        <div>
                          {notifications.map((item, index) => (
                            <NotificationCard notification={item} key={index} />
                          ))}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "15px",
                        }}
                      >
                        <NotificationsOff
                          fontSize="large"
                          sx={{ color: "#57BAB8" }}
                        />
                        <Typography
                          color={"#57BAB8"}
                          fontSize={18}
                          fontWeight={600}
                          textAlign={"center"}
                          marginTop={"15px"}
                        >
                          {"Nenhuma notificação encontrada"}
                        </Typography>
                      </div>
                    )}
                  </div>
                )}
              </Box>
            </Grow>
          </ClickAwayListener>
        </Box>
      )}
      <Container maxWidth="xl" sx={{ height: "95%", ...styles }}>
        {children}
      </Container>
    </Box>
  );
};
