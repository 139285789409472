import {
  Button,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
} from "@mui/material";
import React, { useContext } from "react";

import logo from "../../assets/logo.svg";
import { MainListItems } from "./listItems";
import { Help } from "@mui/icons-material";
import { AuthContext } from "../../providers/AuthProvider";

export const MainSidebar = ({
  visible,
  setVisible,
  supportModal,
  setSupportModal,
}) => {
  const { user } = useContext(AuthContext);

  return (
    <Drawer
      variant="temporary"
      open={visible}
      onClose={() => setVisible(false)}
      sx={{
        width: 180,
      }}
      anchor="top"
      PaperProps={{
        style: {
          backgroundColor: "#fff",
          justifyContent: "space-between",
          // padding: "30px 0px",
          margin: "30px",
          marginTop: "80px",
          borderRadius: "24px",
        },
      }}
    >
      <div style={{ height: "100%", display: "flex" }}>
        {/* <Divider /> */}
        <List>
          <MainListItems />
        </List>
      </div>
    </Drawer>
  );
};
