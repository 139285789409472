import {
  AttachMoney,
  HeadsetMic,
  Home,
  Person,
  Settings,
  Store,
  EventAvailable,
  ContactMail,
  AppSettingsAlt,
  School,
  Work,
  Logout,
} from "@mui/icons-material";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import React, { useContext } from "react";
import { AuthContext } from "../../providers/AuthProvider";
import {
  SidebarItem,
  SidebarLink,
  SiderbarIcon,
  SiderbarIconImage,
} from "./styles";
import { fontSize } from "@mui/system";
import home from "../../assets/home.svg";
import settings from "../../assets/settings.svg";
import knowledge from "../../assets/award.svg";
import skills from "../../assets/briefcase.svg";

export const MainListItems = () => {
  const { user, signOut } = useContext(AuthContext);

  const color = "#EC5569";
  const fontSize = 20;

  return (
    <div
      style={{
        marginTop: "30px",
        marginBottom: "30px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "space-around",
      }}
    >
      <SidebarLink to={"/home"}>
        <SidebarItem button>
          <SiderbarIcon>
            <SiderbarIconImage src={home} alt="house icon" />
          </SiderbarIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: fontSize } }}
            primary="Início"
          />
        </SidebarItem>
      </SidebarLink>

      <SidebarLink to={"/knowledge"}>
        <SidebarItem button>
          <SiderbarIcon>
            <SiderbarIconImage src={knowledge} alt="medal icon" />
          </SiderbarIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: fontSize } }}
            primary="Conhecimentos"
          />
        </SidebarItem>
      </SidebarLink>

      <SidebarLink to={"/skills"}>
        <SidebarItem button>
          <SiderbarIcon>
            <SiderbarIconImage src={skills} alt="briefcase icon" />
          </SiderbarIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: fontSize } }}
            primary="Habilidades"
          />
        </SidebarItem>
      </SidebarLink>

      <SidebarLink to={"/settings"}>
        <SidebarItem button>
          <SiderbarIcon>
            <SiderbarIconImage src={settings} alt="gear icon" />
          </SiderbarIcon>
          <ListItemText
            primaryTypographyProps={{ style: { fontSize: fontSize } }}
            primary="Perfil"
          />
        </SidebarItem>
      </SidebarLink>

      {/* <SidebarItem
        button
        sx={{ position: "absolute", bottom: 0 }}
        onClick={() => signOut()}
      >
        <ListItemIcon sx={{ display: "flex", justifyContent: "center" }}>
          <Logout sx={{ color: "#211e1f" }} />
        </ListItemIcon>
        <ListItemText
          primaryTypographyProps={{ style: { fontSize: fontSize } }}
          primary="Sair"
        />
      </SidebarItem> */}
    </div>
  );
};
