import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Avatar,
  Box,
  Button,
  IconButton,
  Input,
  LinearProgress,
  linearProgressClasses,
  Rating,
  Slider,
  Tooltip,
  Typography,
} from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { AuthContext } from "../../providers/AuthProvider";
import { useNavigate, useParams } from "react-router-dom";
import background from "../../assets/completeProfileBackground.png";
import skillsBackground from "../../assets/skillsbackground.svg";
import cultureGraph from "../../assets/cultureGraph.svg";
import api from "../../utils/api";
import heart from "../../assets/heartIcon.svg";
import heartInactive from "../../assets/heartIconInactive.svg";
import selfieLetterImg from "../../assets/realistic selfie letther vídeo image.svg";
import { Loader } from "../../components/Loader";

import astrounautStandingWave from "../../assets/posesPNG/astronout-standing-wave.png";
import astrounautHoldingTablet from "../../assets/posesPNG/astronout-holding-tablet.png";
import astrounautPointingBoard from "../../assets/posesPNG/astronout-pointing-board.png";
import astrounautStandingRinglight from "../../assets/posesPNG/astronout-standing-ringlight.png";
import CulturalChart from "./graph";

export const Home = () => {
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();
  const { user, updateUser, signOut } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [knowledgeList, setKnowledgeList] = useState([]);

  const culture = user?.isCulturalFitFilled;
  const skills = user?.isTalentKnowledgeSkillFilled;
  const knowledge = user?.isTalentKnowledgeAreaFilled;
  const selfieLetter = user?.isSelfieLetterFilled;

  const removerAposDoisPontos = (texto) => {
    // return texto;
    const indiceDoisPontos = texto.indexOf(":");
    if (indiceDoisPontos !== -1) {
      return texto.substring(0, indiceDoisPontos);
    }
    return texto;
  };

  const handleSkill = (value) => {
    const knowledgeAreas = {
      1: "operation",
      2: "marketing",
      3: "marketplace",
      4: "platform",
      5: "erp",
      6: "designAndMedia",
      7: "dataAndBi",
      8: "socialMedia",
      9: "ia",
    };

    return knowledgeAreas[value] || false;
  };

  const handleKnowledge = (value) => {
    const knowledgeAreas = {
      dont_know: 1,
      beginner: 2,
      can_manage: 3,
      can_teach: 4,
      expert: 5,
    };

    return knowledgeAreas[value] || false;
  };

  const getKnowledge = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/knowledge_skills`);

      if (data.data) {
        setKnowledgeList(data.data.attributes.knowledgeAreaSkills);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    getKnowledge();
  }, []);

  const KnowledgeCard = ({ title, value, editable = false }) => {
    return (
      <Box
        sx={{
          width: "150px",
          height: "75px",
          background:
            "radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%)",
          padding: "12px",
          borderRadius: "10px",
          border: "1px solid var(--fake-white, #E3E3E3)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
          flex: "0 0 auto",
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <Typography
            fontSize={"19px"}
            lineHeight={"22.8px"}
            fontWeight={700}
            align="left"
            color={"#211E1F"}
          >
            {title}
          </Typography>
        </div>
        <Rating
          defaultValue={value}
          precision={1}
          max={3}
          icon={<img src={heart} alt="heart" />}
          emptyIcon={<img src={heartInactive} alt="heart" />}
          readOnly={!editable}
        />
      </Box>
    );
  };

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "90%" : "800px",
            marginTop: "25px",
            alignItems: "center",
            // padding: "20px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "25px",
              width: "100%",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                background:
                  "linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #0E1728 73.78%)",
                backgroundImage: `url(${background})`,
                border: "1px solid #005E72",
                borderRadius: "10px",
                width: "100%",
                aspectRatio: 350 / 150,
                marginBottom: "25px",
                alignItems: "center",
                gap: "10px",
                padding: "18px",
                display: "flex",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "60%",
                }}
              >
                <Typography
                  fontSize={"18px"}
                  lineHeight={"21.6px"}
                  fontWeight={700}
                  align="left"
                  color={"white"}
                >
                  Complete seu perfil
                </Typography>
                <LinearProgress
                  sx={{
                    marginTop: "10px",
                    height: 10,
                    borderRadius: 5,
                    [`&.${linearProgressClasses.colorPrimary}`]: {
                      backgroundColor: "#BEFCF9",
                      border: "2px solid #BEFCF9",
                    },
                    [`& .${linearProgressClasses.bar}`]: {
                      borderRadius: "5px 0px 0px 5px",
                      backgroundColor: "#57BAB8",
                    },
                  }}
                  value={25}
                  variant="determinate"
                />
                <Box
                  sx={{
                    width: "100%",
                    padding: "8px",
                    gap: "10px",
                  }}
                >
                  <Typography
                    fontSize={"13px"}
                    lineHeight={"15.6px"}
                    fontWeight={300}
                    align="left"
                    color={"white"}
                    marginTop={"10px"}
                  >
                    Perfis completos e de qualidade aumentam em 4,5 vezes suas
                    chances de ser contratado por clientes.
                  </Typography>
                </Box>
              </Box>

              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "40%",
                }}
              >
                <img
                  src={astrounautStandingWave}
                  style={{
                    position: "absolute",
                    height: "130%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    top: "45%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </Box>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginBottom: "25px",
                width: "100%",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  backgroundColor: "#F2FFFE",
                  border: "1px solid #BEFCF9",
                  borderRadius: "10px",
                  width: "100%",
                  aspectRatio: 350 / 330,

                  alignItems: "center",
                  gap: "10px",
                  padding: "18px",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    fontSize={"18px"}
                    lineHeight={"21.6px"}
                    fontWeight={700}
                    align="left"
                    color={"#211E1F"}
                  >
                    Habilidades
                  </Typography>
                  <Button
                    color="secondary"
                    sx={{
                      backgroundColor: "white",
                      border: "0.8px solid #57BAB8",
                      borderRadius: "80px",
                      color: "#57BAB8",
                      fontSize: "14px",
                    }}
                    variant="outlined"
                    style={{ textTransform: "none" }}
                    onClick={() => navigate("/skills")}
                  >
                    Ver Tudo
                  </Button>
                </div>
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                  }}
                >
                  <img
                    src={skillsBackground}
                    style={{ width: "100%" }}
                    alt="a circle with a heart inside"
                  />
                  <div style={{ display: "flex", position: "absolute" }}>
                    <Avatar
                      style={{
                        display: "flex",
                        position: "absolute",
                        width: "95px",
                        height: "95px",
                        right: "40px",
                        bottom: "50px",
                      }}
                    />
                    <Avatar
                      style={{
                        display: "flex",
                        position: "absolute",
                        width: "95px",
                        height: "95px",
                        left: "40px",
                        top: "-50px",
                      }}
                    />
                    <Avatar
                      style={{
                        display: "flex",
                        position: "absolute",
                        width: "95px",
                        height: "95px",
                        top: "50px",
                        right: "0px",
                      }}
                    />
                    <Avatar
                      style={{
                        display: "flex",
                        position: "absolute",
                        width: "75px",
                        height: "75px",
                        bottom: "80px",
                        right: "-50px",
                      }}
                    />
                    <Avatar
                      style={{
                        display: "flex",
                        position: "absolute",
                        width: "55px",
                        height: "55px",
                        right: "100px",
                      }}
                    />
                  </div>
                </div>
              </Box>
            </div>

            {!knowledge || knowledgeList?.length === 0 ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#F2FFFE",
                    border: "1px solid #BEFCF9",
                    borderRadius: "10px",
                    width: "100%",
                    aspectRatio: 350 / 155,

                    alignItems: "center",
                    gap: "10px",
                    padding: "18px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "75%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      fontSize={"18px"}
                      lineHeight={"21.6px"}
                      fontWeight={700}
                      align="left"
                      color={"#211E1F"}
                    >
                      Conhecimentos
                    </Typography>
                    <Typography
                      fontSize={"14px"}
                      lineHeight={"16.8px"}
                      fontWeight={500}
                      align="left"
                      color={"#211E1F"}
                      marginTop={"15px"}
                    >
                      Conte-nos mais sobre o que você já aprendeu e no que se
                      especializou ao longo do tempo.
                    </Typography>
                    <Button
                      color="secondary"
                      sx={{
                        marginTop: "15px",
                        backgroundColor: "white",
                        border: "0.8px solid #57BAB8",
                        borderRadius: "80px",
                        color: "#57BAB8",
                        fontSize: "14px",
                      }}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                      onClick={() => navigate("/knowledge")}
                    >
                      Atualizar meus conhecimentos
                    </Button>
                  </Box>

                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "25%",
                    }}
                  >
                    <img
                      src={astrounautHoldingTablet}
                      style={{
                        position: "absolute",
                        height: "100%",
                        maxWidth: "100%",
                        objectFit: "contain",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </div>
                </Box>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#F2FFFE",
                    border: "1px solid #BEFCF9",
                    borderRadius: "10px",
                    width: "100%",
                    aspectRatio: 350 / 155,

                    alignItems: "center",
                    gap: "10px",
                    padding: "18px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize={"18px"}
                      lineHeight={"21.6px"}
                      fontWeight={700}
                      align="left"
                      color={"#211E1F"}
                    >
                      Conhecimentos
                    </Typography>
                    <Button
                      color="secondary"
                      sx={{
                        backgroundColor: "white",
                        border: "0.8px solid #57BAB8",
                        borderRadius: "80px",
                        color: "#57BAB8",
                        fontSize: "14px",
                      }}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                      onClick={() => navigate("/knowledge")}
                    >
                      Ver Tudo
                    </Button>
                  </div>
                  <Box
                    sx={{
                      marginTop: "15px",
                      display: "flex",
                      scrollbarWidth: "none",
                    }}
                  >
                    {loading ? (
                      <Loader size={100} style={{ marginTop: "20px" }} />
                    ) : (
                      <div
                        style={{
                          flexDirection: "row",
                          overflowX: "auto",
                          gap: "10px",
                          scrollbarWidth: "none",
                          display: "flex",
                        }}
                      >
                        {knowledgeList?.length > 0 &&
                          knowledgeList.map((item, index) => (
                            <KnowledgeCard
                              key={index}
                              title={item.optionText}
                              value={handleKnowledge(item.level)}
                            />
                          ))}
                      </div>
                    )}
                  </Box>
                </Box>
              </div>
            )}

            {!culture ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#F2FFFE",
                    border: "1px solid #BEFCF9",
                    borderRadius: "10px",
                    width: "100%",
                    aspectRatio: 350 / 155,
                    alignItems: "center",
                    gap: "10px",
                    padding: "18px",
                    display: "flex",
                    position: "relative",
                  }}
                >
                  <Box
                    sx={{
                      width: "55%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      fontSize={"18px"}
                      lineHeight={"21.6px"}
                      fontWeight={700}
                      align="left"
                      color={"#211E1F"}
                    >
                      Fit Cultural
                    </Typography>
                    <Typography
                      fontSize={"14px"}
                      lineHeight={"16.8px"}
                      fontWeight={500}
                      align="left"
                      color={"#211E1F"}
                      marginTop={"15px"}
                    >
                      Pronto para decolar com a gente? Responda o seu fit
                      cultural!
                    </Typography>
                    <Button
                      onClick={() => navigate("/fit-cultural")}
                      color="secondary"
                      sx={{
                        marginTop: "15px",
                        backgroundColor: "white",
                        border: "0.8px solid #57BAB8",
                        borderRadius: "80px",
                        color: "#57BAB8",
                        fontSize: "14px",
                      }}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                    >
                      Fazer meu fit cultural
                    </Button>
                  </Box>
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "45%",
                    }}
                  >
                    <img
                      src={astrounautPointingBoard}
                      style={{
                        position: "absolute",
                        height: "90%",
                        maxWidth: "100%",
                        objectFit: "contain",
                        top: "50%",
                        left: "50%",
                        transform:
                          "translate(-50%, -50%) scaleX(-1)" /* Gira a imagem 180 graus */,
                        transformOrigin:
                          "center" /* Rotaciona a partir do centro */,
                      }}
                    />
                  </div>
                </Box>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    background:
                      "radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%)",
                    border: "1px solid #E3E3E3",
                    borderRadius: "10px",
                    width: "100%",
                    aspectRatio: 350 / 155,

                    alignItems: "center",
                    gap: "10px",
                    padding: "18px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize={"18px"}
                      lineHeight={"21.6px"}
                      fontWeight={700}
                      align="left"
                      color={"#211E1F"}
                    >
                      Fit Cultural
                    </Typography>
                    <Button
                      color="secondary"
                      sx={{
                        backgroundColor: "white",
                        border: "0.8px solid #57BAB8",
                        borderRadius: "80px",
                        color: "#57BAB8",
                        fontSize: "14px",
                      }}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                      onClick={() => navigate("/fit-cultural")}
                    >
                      Ver Tudo
                    </Button>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      marginTop: "15px",
                    }}
                  >
                    {/* <img
                      src={cultureGraph}
                      alt="graph showing different culture types"
                    /> */}
                    <CulturalChart />
                  </Box>
                </Box>
              </div>
            )}

            {!selfieLetter ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#BEFCF9",
                    border: "1px solid #57BAB8",
                    borderRadius: "10px",
                    width: "100%",
                    aspectRatio: 350 / 178,
                    alignItems: "center",
                    gap: "10px",
                    padding: "18px",
                    position: "relative",
                    display: "flex",
                  }}
                >
                  <Box
                    sx={{
                      width: "50%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography
                      fontSize={"18px"}
                      lineHeight={"21.6px"}
                      fontWeight={700}
                      align="left"
                      color={"#211E1F"}
                    >
                      Selfie Letter
                    </Typography>
                    <Typography
                      fontSize={"14px"}
                      lineHeight={"16.8px"}
                      fontWeight={500}
                      align="left"
                      color={"#211E1F"}
                      marginTop={"15px"}
                    >
                      Estamos entusiasmados em conhecer você melhor!
                    </Typography>
                    <Button
                      color="primary"
                      sx={{
                        marginTop: "15px",
                        backgroundColor: "#EC5569",
                        borderRadius: "80px",
                        color: "white",
                        fontSize: "16px",
                      }}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                      onClick={() => navigate("/selfie-letter")}
                    >
                      Enviar Vídeo
                    </Button>
                  </Box>

                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "45%",
                    }}
                  >
                    <img
                      src={astrounautStandingRinglight}
                      style={{
                        position: "absolute",
                        height: "90%",
                        maxWidth: "100%",
                        objectFit: "contain",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                      }}
                    />
                  </div>
                </Box>
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  marginBottom: "25px",
                  width: "100%",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#F2FFFE",
                    border: "1px solid #BEFCF9",
                    borderRadius: "10px",
                    width: "100%",
                    aspectRatio: 350 / 178,
                    alignItems: "center",
                    padding: "18px",
                  }}
                >
                  <div
                    style={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      fontSize={"18px"}
                      lineHeight={"21.6px"}
                      fontWeight={700}
                      align="left"
                      color={"#211E1F"}
                    >
                      Selfie Letter
                    </Typography>
                    <Button
                      color="secondary"
                      sx={{
                        backgroundColor: "white",
                        border: "0.8px solid #57BAB8",
                        borderRadius: "80px",
                        color: "#57BAB8",
                        fontSize: "14px",
                      }}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                      onClick={() => navigate("/selfie-letter")}
                    >
                      Ver mais
                    </Button>
                  </div>
                  <Box
                    sx={{
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    <img
                      src={selfieLetterImg}
                      alt="video preview"
                      style={{ marginTop: "25px" }}
                    />
                    <Button
                      onClick={() => navigate("/selfie-letter")}
                      color="primary"
                      sx={{
                        marginTop: "25px",
                        backgroundColor: "white",
                        borderRadius: "80px",
                        fontSize: "16px",
                        fontWeight: 500,
                      }}
                      variant="outlined"
                      style={{ textTransform: "none" }}
                    >
                      Enviar outro vídeo
                    </Button>
                  </Box>
                </Box>
              </div>
            )}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};
