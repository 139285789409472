import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { useFormik } from "formik";
import * as yup from "yup";

import { AuthContext } from "../../providers/AuthProvider";
import starBackground from "../../assets/BackgroundLogin.png";
import icon1 from "../../assets/image 12.png";
import iconCapacete from "../../assets/Group 1432.svg";
import iconCheck from "../../assets/Frame 20.png";
import iconNotCheck from "../../assets/Frame 20 (1).png";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import editIcon from "../../assets/Frame 18.png";
import unmask from "../../utils/unmask";
import api from "../../utils/api";

import "./ClientProfile.css";
import ReactInputMask from "react-input-mask";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import { Box, Button, Typography } from "@mui/material";
import { Logout } from "@mui/icons-material";

const ClientProfile = () => {
  const navigate = useNavigate();
  const { updateUser, user, signOut } = useContext(AuthContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [tempPhone, setTempPhone] = useState("");
  const [scree, setScreen] = useState("screen1");

  const checkList = {
    isCulturalFitFilled: user?.isCulturalFitFilled,
    isSelfieLetterFilled: user?.isSelfieLetterFilled,
    isTalentKnowledgeAreaFilled: user?.isTalentKnowledgeAreaFilled,
    isTalentKnowledgeSkillFilled: user?.isTalentKnowledgeSkillFilled,
  };

  const form = useFormik({
    initialValues: {
      name: user?.name || "",
      birthday: user?.birthdate
        ? moment(user.birthdate).format("DD/MM/YYYY")
        : "",
      email: user?.email || "",
      phone: user?.phone || "",
      avatar: user?.avatar || "",
    },

    // validationSchema: yup.object({
    //   money: yup.number("Deve ser um número"),
    //   ratio: yup.number("Deve ser um número").required("Campo obrigatório"),
    // }),

    onSubmit: (values) => updateSettings(values),
  });

  const resetForm = (values) => {
    form.setValues({
      name: values?.name || "",
      birthday: values?.birthdate
        ? moment(values.birthdate).format("DD/MM/YYYY")
        : "",
      email: values?.email || "",
      phone: values?.phone || "",
      avatar: values?.avatar || "",
    });
  };

  const updateSettings = async (values) => {
    setSaveLoading(true);

    if (values?.avatar !== user?.avatar) {
      try {
        const { data } = await api.put(`/talents/talents/${user.id}`, {
          data: {
            attributes: {
              avatar: { data: values?.avatar },
              name: values.name.trim(),
              phone: unmask(values.phone.trim()),
              birthdate: moment(values.birthday, "DD/MM/YYYY").toISOString(),
              email: values.email,
            },
          },
        });
        if (data) {
          updateUser(data);
          resetForm(data.data.attributes);
          toast.success("Dados da conta atualizados com sucesso!");
        }
      } catch (error) {
        //console.log(error);
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
      } finally {
        setSaveLoading(false);
      }
    } else {
      try {
        const { data } = await api.put(`/talents/talents/${user.id}`, {
          data: {
            attributes: {
              name: values.name.trim(),
              phone: unmask(values.phone.trim()),
              birthdate: moment(values.birthday, "DD/MM/YYYY").toISOString(),
              email: values.email,
            },
          },
        });
        if (data) {
          updateUser(data);
          resetForm(data.data.attributes);
          toast.success("Dados da conta atualizados com sucesso!");
        }
      } catch (error) {
        //console.log(error);
        toast.error("Ocorreu um erro ao tentar atualizar os dados da conta.");
      } finally {
        setSaveLoading(false);
      }
    }
  };

  useEffect(() => {
    setTempPhone(form.values.phone);
  }, [form.values.phone]);

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";

      let reader = new FileReader();

      reader.readAsDataURL(file);

      reader.onload = () => {
        baseURL = reader.result;
        // console.log(baseURL);
        resolve(baseURL);
      };
      // console.log(fileInfo);
    });
  };

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    getBase64(file)
      .then((result) => {
        file["base64"] = result;
        // console.log("File Is", file);
        form.setFieldValue(`avatar`, result);
      })
      .catch((error) => {
        if (error?.response?.status) {
          if (error.response.status !== 401) {
            if (
              error?.response?.data?.errors &&
              error?.response?.data?.errors[0]?.title
            ) {
              alert(`${error.response.data.errors[0].title}`);
            } else {
              alert("Something unexpected happened.", "Please try again.");
            }
          } else {
            alert("Session expired", "Please login in again.");
          }
        } else {
          alert("Something unexpected happened.", "Please try again.");
        }
      });
  };

  const contarTrues = (obj) => {
    let contador = 0;

    for (let key in obj) {
      if (obj[key] === true) {
        contador++;
      }
    }

    return contador;
  };

  return (
    <AuthorizedLayout styles={{ padding: 0 }}>
      <div className="containerClientProfile">
        {scree === "screen1" ? (
          <div style={{ position: "relative", top: "10%", height: "80%" }}>
            <img
              src={starBackground}
              alt="star background"
              className="imgBackground"
            />
            <div className="containerPhoto">
              <img src={icon1} alt="icon" className="iconClientProfile1" />

              {/* <img
                src={iconCapacete}
                alt="icon"
                className="iconClientProfile2"
              />
              <div className="oval-container">
                <img
                  src={form.values.avatar}
                  alt="icon"
                  className="iconClientProfile3"
                />
              </div> */}

              <label className="uploadIcon" htmlFor="fupload">
                <img
                  src={editIcon}
                  alt="edit"
                  className="editIcon"
                  htmlFor="fupload"
                />
                <input
                  type="file"
                  id="fupload"
                  name="fupload"
                  className="fupload form-control"
                  accept=".jpeg, .png, .jpg"
                  onChange={handleFileInputChange}
                  style={{ display: "none" }}
                />
              </label>
            </div>
            <div className="contentContainer">
              {contarTrues(checkList) <= 3 && (
                <div className="containerCardHeader">
                  <div className="header">
                    <div className="heaTitle">Complete seu perfil</div>

                    <div className="bar">
                      <div className="fill">
                        <span
                          className="leads"
                          style={{
                            width: `${(
                              (contarTrues(checkList) / 4) *
                              100
                            ).toFixed(2)}%`,
                          }}
                        ></span>
                      </div>
                    </div>
                  </div>

                  <div className="headSUb">
                    Perfis completos e de qualidade aumentam em 4,5 vezes suas
                    chances de ser contratado por clientes.
                  </div>

                  <div className="button" onClick={() => setScreen("screen2")}>
                    Clique aqui
                  </div>
                </div>
              )}
              <div style={{ marginTop: "20px" }} />

              <div className="containerInput" style={{ height: "100px" }}>
                <div className="text">Nome Completo</div>
                <input
                  type="text"
                  label="Nome completo"
                  className="inputLogin"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.name}
                  name="name"
                />
                {form.touched.name && form.errors.name && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.name}
                  </div>
                )}
              </div>

              <div className="containerInput" style={{ height: "100px" }}>
                <div className="text">Data de nascimento</div>

                <ReactInputMask
                  mask="99/99/9999"
                  value={form.values.birthday}
                  onChange={(e) =>
                    form.setFieldValue(`birthday`, e.target.value)
                  }
                  onBlur={() => {
                    form.setFieldTouched(`birthday`, true);
                  }}
                  className="inputLogin"
                />
                {form.touched.birthday && form.errors.birthday && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.birthday}
                  </div>
                )}
              </div>

              <div className="containerInput" style={{ height: "100px" }}>
                <div className="text">Email</div>
                <input
                  type="email"
                  label="E-mail"
                  className="inputLogin"
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                  value={form.values.email}
                  name="email"
                />
                {form.touched.email && form.errors.email && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.email}
                  </div>
                )}
              </div>

              <div className="containerInput" style={{ height: "100px" }}>
                <div className="text">Telefone</div>
                <ReactInputMask
                  mask={
                    tempPhone.replace(/\s/g, "").length > 13
                      ? "(99) 9 9999-9999"
                      : "(99) 9999-99999"
                  }
                  value={form.values.phone}
                  onChange={(e) => form.setFieldValue(`phone`, e.target.value)}
                  onBlur={() => {
                    form.setFieldTouched(`phone`, true);
                  }}
                  className="inputLogin"
                />
                {form.touched.phone && form.errors.phone && (
                  <div style={{ color: "red" }} className="text">
                    {form.errors.phone}
                  </div>
                )}
              </div>

              <div
                className={`buttonClientProfile ${saveLoading && "loading"}`}
                onClick={form.submitForm}
                style={{ width: "75vw" }}
              >
                {saveLoading ? "Salvando" : "Salvar"}
              </div>

              <Button
                sx={{
                  marginTop: "20px",
                  width: "75vw",
                  borderRadius: "100px",
                  height: "43px",
                }}
                onClick={() => signOut()}
                variant="outlined"
                style={{ textTransform: "none" }}
              >
                <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Logout />
                </Box>
                <Typography
                  fontFamily={"Barlow Semi Condensed"}
                  textAlign={"center"}
                  fontWeight={700}
                >
                  Sair
                </Typography>
              </Button>
            </div>
          </div>
        ) : (
          <div style={{ position: "relative", top: "10%", height: "80%" }}>
            <img
              src={starBackground}
              alt="star background"
              className="imgBackground"
            />
            <div className="contentContainer2">
              <div className="containerCardHeader">
                <div className="header">
                  <div className="heaTitle">Complete seu perfil</div>

                  <div className="bar">
                    <div className="fill">
                      <span
                        className="leads"
                        style={{
                          width: `${(
                            (contarTrues(checkList) / 4) *
                            100
                          ).toFixed(2)}%`,
                        }}
                      ></span>
                    </div>
                  </div>

                  <div className="headSUb">
                    Perfis completos e de qualidade aumentam em 4,5 vezes suas
                    chances de ser contratado por clientes.
                  </div>
                </div>

                <div className="containerPhoto2">
                  <img src={icon1} alt="icon" className="iconClientProfile1" />

                  <img
                    src={iconCapacete}
                    alt="icon"
                    className="iconClientProfile2"
                  />
                  <div className="oval-container">
                    <img
                      src={form.values.avatar}
                      alt="icon"
                      className="iconClientProfile3"
                    />
                  </div>
                </div>
              </div>

              <div
                className="cardCheck"
                style={{
                  backgroundColor:
                    checkList["isTalentKnowledgeAreaFilled"] && "#BEFCF9",
                }}
                onClick={() => navigate("/")}
              >
                <img
                  src={
                    checkList["isTalentKnowledgeAreaFilled"]
                      ? iconCheck
                      : iconNotCheck
                  }
                  alt="icon"
                />
                <div>Habilidades adicionais</div>
              </div>

              <div
                className="cardCheck"
                style={{
                  backgroundColor:
                    checkList["isTalentKnowledgeSkillFilled"] && "#BEFCF9",
                }}
                onClick={() => navigate("/")}
              >
                <img
                  src={
                    checkList["isTalentKnowledgeSkillFilled"]
                      ? iconCheck
                      : iconNotCheck
                  }
                  alt="icon"
                />
                <div>Conhecimentos</div>
              </div>

              <div
                className="cardCheck"
                style={{
                  backgroundColor:
                    checkList["isCulturalFitFilled"] && "#BEFCF9",
                }}
                onClick={() => navigate("/fit-cultural")}
              >
                <img
                  src={
                    checkList["isCulturalFitFilled"] ? iconCheck : iconNotCheck
                  }
                  alt="icon"
                />
                <div>Fit Cultural</div>
              </div>

              <div
                className="cardCheck"
                style={{
                  backgroundColor:
                    checkList["isSelfieLetterFilled"] && "#BEFCF9",
                }}
                onClick={() => navigate("/selfie-letter")}
              >
                <img
                  src={
                    checkList["isSelfieLetterFilled"] ? iconCheck : iconNotCheck
                  }
                  alt="icon"
                />
                <div>Selfie Letter</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </AuthorizedLayout>
  );
};

export default ClientProfile;
