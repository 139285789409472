/* eslint-disable react-hooks/exhaustive-deps */
import React, { createContext, useEffect, useState } from "react";
import api from "../utils/api";
import moment from "moment";
import { toast } from "react-toastify";
import unmask from "../utils/unmask";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authData, setAuthData] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const token = localStorage.getItem("@ecomlovers:token");
    const userStorage = localStorage.getItem("@ecomlovers:user");

    if (userStorage) {
      setAuthData(JSON.parse(userStorage));
    }
    if (token) {
      api.defaults.headers.Authorization = `Bearer ${token}`;
    }
    setLoading(false);
  }, []);

  const signIn = async ({ email, password }) => {
    try {
      const { data } = await api.post(`/talents/authenticate`, {
        email: email.toLowerCase().trim(),
        password: password.trim(),
      });
      const user = data.data.attributes;
      user.id = data.data.id;

      if (user.authToken) {
        // Adding it's token to all requests for now on.
        api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
        localStorage.setItem("@ecomlovers:token", user.authToken);
        localStorage.setItem(
          "@ecomlovers:user",
          JSON.stringify({ ...user, id: data.data.id })
        );
        setAuthData({ ...user, id: data.data.id });
      } else {
        throw new Error("Token not found");
      }
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const handleAnswer = async (questioId, answerText, token) => {
    try {
      if (token) {
        const { data } = await api.post(
          `/talents/knowledge_area/answer_questions`,
          {
            data: {
              attributes: {
                questionId: questioId,
                answerText: answerText.trim(),
              },
            },
          },
          token && {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } else {
        const { data } = await api.post(`/talents/answer_questions`, {
          data: {
            attributes: { questioId: questioId, answerText: answerText.trim() },
          },
        });
        return data;
      }
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const handleAnswerKnowledge = async (questioId, options, token) => {
    try {
      const { data } = await api.post(
        `/talents/knowledge_area/answer_questions`,
        {
          data: {
            attributes: {
              questionId: questioId,
              answerOptions: options?.map((item) => ({ id: item })),
            },
          },
        },
        token && {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const handleAnswerAreas = async (questioId, options, token) => {
    try {
      const { data } = await api.post(
        `/talents/knowledge_area/answer_questions`,
        {
          data: {
            attributes: {
              questionId: questioId,
              answerOptions: options?.map((item) => ({
                id: item.id,
                level: item?.level || 0,
              })),
            },
          },
        },
        token && {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      return data;
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const handleAnswerFile = async (questioId, answerFile, token) => {
    try {
      if (token) {
        const { data } = await api.post(
          `/talents/knowledge_area/answer_questions`,
          {
            data: {
              attributes: {
                questionId: questioId,
                avatar: { data: answerFile },
              },
            },
          },
          token && {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        return data;
      } else {
        const { data } = await api.post(`/talents/answer_questions`, {
          data: {
            attributes: {
              questioId: questioId,
              avatar: { data: answerFile },
            },
          },
        });
        return data;
      }
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const handleQuestion = async () => {
    try {
      const { data } = await api.get(`/questions/questions`);
      return data;
    } catch (error) {
      //setAuthData("teste");
      throw new Error(error);
    }
  };

  const signUp = async (data) => {
    const user = data.attributes;
    user.id = data.id;

    if (user?.authToken) {
      // Adding it's token to all requests for now on.
      api.defaults.headers.Authorization = `Bearer ${user.authToken}`;
      localStorage.setItem("@ecomlovers:token", user.authToken);
      localStorage.setItem(
        "@ecomlovers:user",
        JSON.stringify({ ...user, id: data.id, userType: "partner" })
      );
      setAuthData({ ...user, id: data.id, userType: "partner" });

      toast.success("Cadastro realizado com sucesso!");

      return "success";
    }
  };

  const updateUser = async (data) => {
    const user = data.data.attributes;
    localStorage.setItem(
      "@ecomlovers:user",
      JSON.stringify({
        ...user,
        id: data.data.id,
        authToken: authData.authToken,
        expiresAt: authData.expiresAt,
      })
    );
    setAuthData({
      ...user,
      id: data.data.id,
      authToken: authData.authToken,
      expiresAt: authData.expiresAt,
    });
  };

  const signOutIfTokenExpired = async () => {
    const user = await localStorage.getItem("@ecomlovers:user");
    if (user) {
      const parsedUser = JSON.parse(user);

      if (moment(parsedUser.expiresAt).isBefore(new Date())) {
        signOut();
      } else {
        api.defaults.headers.Authorization = `Bearer ${parsedUser.authToken}`;
        setAuthData(parsedUser);
      }
    } else {
      signOut();
    }
  };

  useEffect(() => {
    signOutIfTokenExpired();
  }, []);

  const signOut = async () => {
    //   await AsyncStorage.multiRemove(['@Allmidz: user']);

    api.defaults.headers.Authorization = null;

    setAuthData("");
    localStorage.setItem("@ecomlovers:token", "");
    localStorage.setItem("@ecomlovers:user", "");
  };

  if (loading) {
    return <h1>Loading ...</h1>;
  }

  return (
    <AuthContext.Provider
      value={{
        user: authData,
        loading,
        updateUser,
        signIn,
        signOut,
        signUp,
        handleQuestion,
        handleAnswer,
        handleAnswerFile,
        handleAnswerKnowledge,
        handleAnswerAreas,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
