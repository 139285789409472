import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import { Box, Button, IconButton, Input, Typography } from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { InputWithLabel } from "../../components/InputWithLabel";
import { useFormik } from "formik";
import profileCharacter from "../../assets/astronaut 3.svg";
import addPhoto from "../../assets/addPhoto.svg";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import unmask from "../../utils/unmask";
import { toast } from "react-toastify";
import moment from "moment";
import selectIcon from "../../assets/selectIcon.png";
import selectedIcon from "../../assets/selectedIcon.png";
import notificationIcon from "../../assets/skillNotification.svg";
import { useNavigate } from "react-router-dom";

import astrounautSittingNotebook from "../../assets/posesPNG/astronout-sitting-notebook.png";

export const Skills = () => {
  const [selectedMenu, setSelectedMenu] = useState("basic");
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();

  const [saveLoading, setSaveLoading] = useState(false);

  const { user, updateUser, signOut } = useContext(AuthContext);
  const [selectKnowledge, setSelectedKnowledge] = useState([]);
  const [seenSkills, setSeenSkills] = useState([]);

  const options = [
    {
      id: 1,
      option_text: "Operação: Logística, SAC, RH, Administrativo",
    },
    {
      id: 2,
      option_text: "Marketing: SEO, Tráfego Pago, CRM, Copywriting",
    },
    {
      id: 3,
      option_text: "Marketplaces: MercadoLivre, Amazon, Magalu",
    },
    {
      id: 4,
      option_text: "Plataformas: VTEX, Nuvemshop, Shopify, Tray, WooCommerce",
    },
    {
      id: 5,
      option_text: "ERPs: Bling, Tiny, SAP, Linx, TOTVS, API Integration",
    },
    {
      id: 6,
      option_text:
        "Design \u0026 Media: Photoshop, Capcut, Premiere, Illustrator",
    },
    {
      id: 7,
      option_text: "Dados \u0026 BI: GA4, Data Studio, Hotjar, PowerBI",
    },
    {
      id: 8,
      option_text: "Social Media: Media buying, TikTok, YouTube, influencers",
    },
    {
      id: 9,
      option_text: "AI: ChatGPT, Midjourney, Adobe Sensei",
    },
    {
      id: 10,
      option_text: "Dia-a-dia",
    },
  ];

  useEffect(() => {
    const hasSeenSkill = JSON.parse(
      localStorage.getItem("@ecomlovers:seenSkills")
    );

    if (hasSeenSkill) {
      setSeenSkills(hasSeenSkill.map((item) => JSON.parse(item)));
    }

    if (!hasSeenSkill) {
      localStorage.setItem(
        "@ecomlovers:seenSkills",
        JSON.stringify(
          options.map((item) => JSON.stringify({ id: item.id, seen: false }))
        )
      );
    }
  }, []);

  // useEffect(() => {
  //   console.log(seenSkills);
  //   if (seenSkills.length > 0) {
  //     localStorage.setItem(
  //       "@ecomlovers:seenSkills",
  //       JSON.stringify(seenSkills.map((item) => JSON.stringify(item)))
  //     );
  //   }
  // }, [seenSkills]);

  const handleToggleKnowledge = (item) => {
    if (!seenSkills.find((skill) => skill.id === item.id)?.seen) {
      setSeenSkills(
        seenSkills.map((skill) =>
          skill.id === item.id ? { id: skill.id, seen: true } : skill
        )
      );

      localStorage.setItem(
        "@ecomlovers:seenSkills",
        JSON.stringify(
          seenSkills.map((skill) =>
            skill.id === item.id
              ? JSON.stringify({ id: skill.id, seen: true })
              : JSON.stringify(skill)
          )
        )
      );
    }
    navigate(`/skills/${item.id}`);
  };

  const removerAposDoisPontos = (texto) => {
    // return texto;
    const indiceDoisPontos = texto.indexOf(":");
    if (indiceDoisPontos !== -1) {
      return texto.substring(0, indiceDoisPontos);
    }
    return texto;
  };

  const handleActive = (value) => {
    const knowledgeAreas = {
      1: user?.talentKnowledgeArea?.operation,
      2: user?.talentKnowledgeArea?.marketing,
      3: user?.talentKnowledgeArea?.marketplace,
      4: user?.talentKnowledgeArea?.platform,
      5: user?.talentKnowledgeArea?.erp,
      6: user?.talentKnowledgeArea?.designAndMedia,
      7: user?.talentKnowledgeArea?.dataAndBi,
      8: user?.talentKnowledgeArea?.socialMedia,
      9: user?.talentKnowledgeArea?.ia,
      10: user?.dayToDay,
    };

    return knowledgeAreas[value] || false;
  };

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "100%" : "800px",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: "30px",
              paddingBottom: "30px",
              width: "100%",
              borderBottom: "1px solid #F6F2F3",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#BEFCF9",
                border: "1px solid #57BAB8",
                borderRadius: "10px",
                width: "100%",
                aspectRatio: 350 / 115,
                marginBottom: "28px",
                display: "flex",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "60%",
                  padding: "24px",
                }}
              >
                <Typography
                  fontSize={"18px"}
                  lineHeight={"21.6px"}
                  fontWeight={700}
                  align="left"
                  color={"#211E1F"}
                >
                  Atualização de habilidades
                </Typography>
                <Typography
                  fontSize={"14px"}
                  lineHeight={"16.8px"}
                  fontWeight={500}
                  align="left"
                  color={"#211E1F"}
                  marginTop={"12px"}
                >
                  Mantenha-se atualizado com as habilidades essenciais para o
                  sucesso.
                </Typography>
              </Box>

              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "40%",
                }}
              >
                <img
                  src={astrounautSittingNotebook}
                  style={{
                    position: "absolute",
                    height: "110%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    top: "40%",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </Box>
            <Typography
              fontSize={"15px"}
              lineHeight={"18px"}
              fontWeight={400}
              align="center"
            >
              Monitore seu progresso nas principais ferramentas e tecnologias
              usadas. Ajuste seu nível de competência conforme você avança e
              veja seu crescimento profissional.
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "6px",
              justifyContent: "space-around",
              marginTop: "25px",
              marginBottom: "25px",
            }}
          >
            {options.map((item, index) => (
              <Button
                variant="outlined"
                color="secondary"
                key={index}
                sx={{
                  width: "49%",
                  borderRadius: "10px",
                  fontWeight: "500",
                  color: handleActive(item.id) ? "#FFFFFF" : "#211E1F",
                  borderColor: handleActive(item.id) ? "#BEFCF9" : "#BFB7B8",
                  padding: "18px 10px 18px 10px",
                  backgroundColor: handleActive(item.id) ? "#57BAB8" : null,

                  ":hover": {
                    color: handleActive(item.id) ? "#FFFFFF" : "#57BAB8",

                    backgroundColor: handleActive(item.id) ? "#57BAB8" : null,

                    opacity: handleActive(item.id) ? "0.75" : "1",
                  },
                }}
                onClick={() => handleToggleKnowledge(item)}
              >
                <div
                  style={{
                    display: "flex",
                    width: "20%",
                    justifyContent: "center",
                  }}
                >
                  <img
                    src={
                      handleActive(item.id)
                        ? selectedIcon
                        : seenSkills.find((skill) => skill.id === item.id)?.seen
                        ? selectIcon
                        : notificationIcon
                    }
                    alt="edit icon"
                  />
                </div>
                <div style={{ display: "flex", width: "80%" }}>
                  {removerAposDoisPontos(item.option_text)}
                </div>
              </Button>
            ))}
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};
