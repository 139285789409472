import AWS from "aws-sdk";
import moment from "moment";
import CryptoJS from "crypto-js";

const hashFile = async (filePath) => {
  try {
    const fileBuffer = await fetch(filePath).then(res => res.arrayBuffer());
    const wordArray = CryptoJS.lib.WordArray.create(new Uint8Array(fileBuffer));
    const hash = CryptoJS.MD5(wordArray);
    const hexDigest = CryptoJS.enc.Base64.stringify(hash);
    return hexDigest;
  } catch (error) {
    console.error("Erro ao calcular o hash:", error);
    throw error;
  }
};

const S3_BUCKET = 'ecom-lovers';
const REGION = 'us-east-1';

AWS.config.update({
  accessKeyId: 'AKIAUJSKJDD5VHFQAWVO',
  secretAccessKey: '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',
});

const s3 = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

export const uploadFile = async (file) => {

  const hashKey = `ecom-lovers-${moment(new Date()).format(
    "MMMDDYYYY-HHmmss"
  )}`;

  const fileExt = file.name.split(".").pop();
  const fileMime = fileExt.startsWith("mp4") ? `video/${fileExt}` : `image/${fileExt}`;

   // Gera o blob
   const fileBlob = await fetch(file).then(res => res.blob());
   const fileName = fileMime.startsWith("video") ? `video.${fileExt}` : `photo.${fileExt}`;
   const fileData = new File([fileBlob], fileName);

  const checksum = await hashFile(fileData);

  const params = {
    Bucket: S3_BUCKET,
    Key: hashKey,
    Body: file,
  };

  const resultParams = {
    file: {
      filename: file.name,
      byte_size: file.size,
      checksum: checksum,
      key: hashKey,
      content_type: file.type,
    },
  };

  const upload = s3
  .upload(params)
  .on("httpUploadProgress", (evt) => {
    console.log(
      "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
    );
  })
  .promise();

  const data = await upload.then((response) => {
    if (response) {
      return resultParams;
    }
  });

  if (data) return data;
};
