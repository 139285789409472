import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import * as yup from "yup";

import { AuthContext } from "../../providers/AuthProvider";
import TokenInput from "./components/TokenInput";
import starBackground from "../../assets/BackgroundLogin.png";
import icon1 from "../../assets/iconLogin2.png";
import icon2 from "../../assets/iconLogin1.png";
import logo from "../../assets/ecomlovers-logo-transparente.svg";

import "./Login.css";
import api from "../../utils/api";

const Login = () => {
  const navigate = useNavigate();
  const { signIn } = useContext(AuthContext);
  const [loadingToken, setLoadingToken] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [currentScreen, setCurrentScreen] = useState("screen1");
  const [token, setToken] = useState(Array(6).fill(""));
  const [checkToken, setCheckToken] = useState(false);

  const formLogin = useFormik({
    initialValues: {
      email: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Formato de email inválido.")
        .required("Email é obrigatório!"),
    }),

    onSubmit: (values) => handleContinue(values),
  });

  const handleContinue = async (values) => {
    const { email } = values;
    setLoadingToken(true);
    try {
      const { data } = await api.post(`/talents/request_password`, {
        email: email,
      });
      setLoadingToken(false);
      toast.success("Nova senha enviada para o seu email");
      setCurrentScreen("screen3");
    } catch (error) {
      toast.error("Credenciais inválidas");
    } finally {
      setLoadingToken(false);
    }
  };

  const joinStrings = (strings) => {
    return strings.join("");
  };

  const handleLogin = async () => {
    if (checkAllStringsFilled(token)) {
      try {
        setLoading(true);
        await signIn({
          email: formLogin.values.email,
          password: joinStrings(token),
        });
        setLoading(false);
      } catch (error) {
        toast.error("Credenciais inválidas");
      } finally {
        setLoading(false);
      }
    } else {
      setCheckToken(true);
    }
  };

  const checkAllStringsFilled = (strings) => {
    return strings.every((str) => str.trim() !== "");
  };

  const renderScreen = () => {
    switch (currentScreen) {
      case "screen2":
        return (
          <div className="contentContainer">
            <img
              src={currentScreen === "screen1" ? icon1 : icon2}
              alt="icon"
              className={
                currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"
              }
            />
            <div className="title">Olá, entre por aqui!</div>

            <div className="containerInput" style={{ height: "100px" }}>
              <div className="text">Preencha com o seu email</div>
              <input
                type="email"
                label="E-mail"
                className="inputLogin"
                onChange={formLogin.handleChange}
                onBlur={formLogin.handleBlur}
                value={formLogin.values.email}
                name="email"
              />
              {formLogin.touched.email && formLogin.errors.email && (
                <div style={{ color: "red" }} className="text">
                  {formLogin.errors.email}
                </div>
              )}
            </div>

            <div className="contianerButtonsLogin">
              <div
                className="buttonLogin style1"
                onClick={formLogin.handleSubmit}
              >
                {loadingToken ? (
                  <span className="loading">Carregando...</span>
                ) : (
                  "Enviar token de acesso"
                )}
              </div>
            </div>
          </div>
        );
      case "screen3":
        return (
          <div className="contentContainer">
            <img
              src={currentScreen === "screen1" ? icon1 : icon2}
              alt="icon"
              className={
                currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"
              }
            />
            <div className="subtitle">
              Acesse seu email e preencha com o token que foi enviado!
            </div>

            <div className="containerInput" style={{ height: "100px" }}>
              <div className="text">Preencha com o token</div>
              <TokenInput token={token} setToken={setToken} />
              <div style={{ color: "red" }} className="text">
                {checkToken && "token é obrigatório"}
              </div>
            </div>

            <div className="contianerButtonsLogin">
              <button className={`buttonLogin style1`} onClick={handleLogin}>
                {loading ? (
                  <span className="loading">Carregando...</span>
                ) : (
                  "Entrar"
                )}
              </button>
            </div>
          </div>
        );
      default:
        return (
          <div className="contentContainer">
            <img
              src={currentScreen === "screen1" ? icon1 : icon2}
              alt="icon"
              className={
                currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"
              }
            />
            <div className="title">Bem-Vindo ao eComLovers!</div>
            <div className="subtitle">
              Pronto para decolar em uma jornada incrível no mundo do Ecommerce?
            </div>

            <div className="contianerButtonsLogin">
              <div
                className="buttonLogin style1"
                onClick={() => {
                  navigate("/cadastro");
                }}
              >
                Vamos começar agora!
              </div>
              <div
                className="buttonLogin style2"
                onClick={() => setCurrentScreen("screen2")}
              >
                Acessar minha conta
              </div>
            </div>
          </div>
        );
    }
  };

  return (
    <div className="containerLogin">
      <img src={logo} alt="logo" className="logo" />
      <div style={{ position: "relative", top: "20%", height: "80%" }}>
        <img
          src={starBackground}
          alt="star background"
          className="imgBackground"
        />
        {/* <img
        src={currentScreen === "screen1" ? icon1 : icon2}
        alt="icon"
        className={currentScreen === "screen1" ? "iconlogin1" : "iconlogin2"}
      /> */}

        {renderScreen(currentScreen)}
      </div>
    </div>
  );
};

export default Login;
