import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Box,
  Button,
  Icon,
  IconButton,
  Input,
  Rating,
  Typography,
} from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { InputWithLabel } from "../../components/InputWithLabel";
import { useFormik } from "formik";
import profileCharacter from "../../assets/astronaut 3.svg";
import addPhoto from "../../assets/addPhoto.svg";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import unmask from "../../utils/unmask";
import { toast } from "react-toastify";
import moment from "moment";
import heart from "../../assets/heartIcon.svg";
import heartInactive from "../../assets/heartIconInactive.svg";
import info from "../../assets/info.svg";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";

import astrounautHoldingTablet from "../../assets/posesPNG/astronout-holding-tablet.png";

export const Knowledge = () => {
  const [selectedMenu, setSelectedMenu] = useState("yourSkills");
  const [knowledge, setKnowledge] = useState([]);
  const [knowledgeExtra, setKnowledgeExtra] = useState([]);
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(true);
  const [saveLoading, setSaveLoading] = useState(false);

  const { user, updateUser, signOut } = useContext(AuthContext);
  const [selectKnowledge, setSelectedKnowledge] = useState([]);

  const removerAposDoisPontos = (texto) => {
    // return texto;
    const indiceDoisPontos = texto.indexOf(":");
    if (indiceDoisPontos !== -1) {
      return texto.substring(0, indiceDoisPontos);
    }
    return texto;
  };

  const handleKnowledge = (value) => {
    const knowledgeAreas = {
      dont_know: 1,
      beginner: 2,
      can_manage: 3,
      can_teach: 4,
      expert: 5,
    };

    return knowledgeAreas[value] || false;
  };

  const getKnowledge = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(`/talents/knowledge_skills`);

      if (data.data) {
        setKnowledge(data.data.attributes.knowledgeAreaSkills);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  };

  const getKnowledgeExtra = async () => {
    setLoading(true);
    try {
      const { data } = await api.get(
        `/talents/knowledge_skills/knowledge_skills_options`
      );

      if (data.data) {
        setKnowledgeExtra(
          data.data.map((item) => ({ ...item.attributes, id: item.id }))
        );
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (selectedMenu === "yourSkills") getKnowledge();
    else getKnowledgeExtra();
  }, [selectedMenu]);

  const KnowledgeCard = ({ title, value, editable = false }) => {
    return (
      <Box
        sx={{
          height: "75px",
          background:
            "radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%)",

          padding: "12px",
          borderRadius: "10px",
          border: "1px solid var(--fake-white, #E3E3E3)",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-evenly",
        }}
      >
        <div
          style={{
            display: "flex",
            marginBottom: "10px",
            justifyContent: "space-between",
          }}
        >
          <Typography
            fontSize={"19px"}
            lineHeight={"22.8px"}
            fontWeight={700}
            align="left"
            color={"#211E1F"}
          >
            {title}
          </Typography>
          <IconButton sx={{ padding: 0 }}>
            <img src={info} alt="info" />
          </IconButton>
        </div>
        <Rating
          defaultValue={value}
          precision={1}
          max={3}
          icon={<img src={heart} alt="heart" />}
          emptyIcon={<img src={heartInactive} alt="heart" />}
          readOnly={!editable}
        />
      </Box>
    );
  };

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "100%" : "800px",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              width: "100%",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#BEFCF9",
                border: "1px solid #57BAB8",
                borderRadius: "10px",
                width: "100%",
                aspectRatio: 350 / 135,
                marginBottom: "28px",
                display: "flex",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "60%",
                  padding: "24px",
                }}
              >
                <Typography
                  fontSize={"18px"}
                  lineHeight={"21.6px"}
                  fontWeight={700}
                  align="left"
                  color={"#211E1F"}
                >
                  Atualização de conhecimentos
                </Typography>
                <Typography
                  fontSize={"14px"}
                  lineHeight={"16.8px"}
                  fontWeight={500}
                  align="left"
                  color={"#211E1F"}
                  marginTop={"12px"}
                >
                  Aprimore suas especialidades para se destacar no mercado.
                </Typography>
              </Box>

              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "40%",
                }}
              >
                <img
                  src={astrounautHoldingTablet}
                  style={{
                    position: "absolute",
                    height: "115%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    top: "40%",
                    left: "40%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "6px",
              justifyContent: "space-around",
              paddingRight: "6px",
              paddingLeft: "6px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                backgroundColor: "#1E1E1E",
                borderRadius: "20px",
                marginBottom: "40px",
              }}
            >
              <div style={{ width: "100%" }}>
                <Button
                  color="secondary"
                  sx={{
                    width: "50%",
                    backgroundColor:
                      selectedMenu === "yourSkills" ? "#313746" : "#091023",
                    color: "#FFFFFF",
                    borderWidth: "0px",
                    borderRadius: "20px 20px 0px 0px",
                    fontSize: "14px",
                    lineHeight: "19.6px",
                    padding: "20px 10px 20px 10px",

                    opacity: selectedMenu === "yourSkills" ? "100%" : "50%",

                    ":hover": {
                      borderWidth: 0,
                      opacity: "75%",
                      backgroundColor:
                        selectedMenu === "yourSkills" ? "#313746" : "#091023",
                    },
                  }}
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  onClick={() => {
                    setSelectedMenu("yourSkills");
                  }}
                >
                  Suas Habilidades
                </Button>
                <Button
                  color="secondary"
                  sx={{
                    width: "50%",
                    backgroundColor:
                      selectedMenu === "extraSkills" ? "#313746" : "#091023",
                    color: "#FFFFFF",
                    borderWidth: "0px",
                    borderRadius: "20px 20px 0px 0px",
                    fontSize: "14px",
                    lineHeight: "19.6px",
                    padding: "20px 10px 20px 10px",

                    opacity: selectedMenu === "extraSkills" ? "100%" : "50%",

                    ":hover": {
                      borderWidth: 0,
                      opacity: "75%",
                      backgroundColor:
                        selectedMenu === "extraSkills" ? "#313746" : "#091023",
                    },
                  }}
                  variant="outlined"
                  style={{ textTransform: "none" }}
                  onClick={() => {
                    setSelectedMenu("extraSkills");
                  }}
                >
                  Habilidades Adicionais
                </Button>
              </div>

              {selectedMenu === "yourSkills" ? (
                <Box
                  sx={{
                    padding: "25px",
                    backgroundColor: "#313746",
                    borderRadius: "0px 0px 20px 20px",
                  }}
                >
                  <Typography
                    fontSize={"16px"}
                    lineHeight={"19.2px"}
                    fontWeight={300}
                    align="left"
                    color={"#FFFFFF"}
                    marginBottom={"10px"}
                  >
                    Esses conhecimentos refletem as suas principais habilidades
                    e são automaticamente adicionados. Eles mostram o que você
                    já domina! Para explorar novas áreas, vá para{" "}
                    <Typography component="span" fontWeight="bold">
                      Habilidades Adicionais.
                    </Typography>
                  </Typography>

                  <div
                    style={{
                      gap: "12px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {/* <KnowledgeCard title={"Gestão de Pessoas"} value={3} />
                    <KnowledgeCard title={"Tráfego"} value={1} />
                    <KnowledgeCard title={"SEO"} value={2} />
                    <KnowledgeCard title={"Análise de Dados"} value={2} />
                    <KnowledgeCard title={"Gestão de Produtos"} value={2} />
                    <KnowledgeCard title={"Atendimento ao Cliente"} value={3} /> */}

                    {loading ? (
                      <Loader size={100} style={{ marginTop: "20px" }} />
                    ) : (
                      knowledge?.length > 0 &&
                      knowledge.map((item, index) => (
                        <KnowledgeCard
                          key={index}
                          title={item.optionText}
                          value={handleKnowledge(item.level)}
                        />
                      ))
                    )}
                  </div>
                </Box>
              ) : (
                <Box
                  sx={{
                    padding: "25px",
                    backgroundColor: "#313746",
                    borderRadius: "0px 0px 20px 20px",
                  }}
                >
                  <Typography
                    fontSize={"16px"}
                    lineHeight={"19.2px"}
                    fontWeight={300}
                    align="left"
                    color={"#FFFFFF"}
                    marginBottom={"10px"}
                  >
                    Quer expandir ainda mais suas competências? Aqui você pode
                    adicionar habilidades extras e destacar tudo o que mais te
                    interessa.
                  </Typography>

                  <div
                    style={{
                      gap: "12px",
                      display: "flex",
                      flexDirection: "column",
                    }}
                  >
                    {loading ? (
                      <Loader size={100} style={{ marginTop: "20px" }} />
                    ) : (
                      knowledgeExtra?.length > 0 &&
                      knowledgeExtra.map((item, index) => (
                        <KnowledgeCard
                          key={index}
                          title={item.optionText}
                          value={handleKnowledge(item.level)}
                          editable
                        />
                      ))
                    )}

                    {/* <KnowledgeCard
                      title={"Gestão de Pessoas"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard title={"Tráfego"} value={0} editable />
                    <KnowledgeCard title={"SEO"} value={0} editable />
                    <KnowledgeCard
                      title={"Análise de Dados"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard
                      title={"Gestão de Produtos"}
                      value={0}
                      editable
                    />
                    <KnowledgeCard
                      title={"Atendimento ao Cliente"}
                      value={0}
                      editable
                    /> */}
                  </div>
                </Box>
              )}
            </Box>
          </div>
        </div>
      </div>
    </AuthorizedLayout>
  );
};
