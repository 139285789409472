import {
  AppBar,
  Avatar,
  Button,
  IconButton,
  Toolbar,
  Typography,
} from "@mui/material";
import { GridMenuIcon } from "@mui/x-data-grid";
import React, { useContext } from "react";
import {
  Navigate,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import notificationIcon from "../../assets/notification.svg";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import { Notifications } from "@mui/icons-material";
import useIsMobileDevice from "../../utils/mobileCheck";

export const MainTopbar = ({
  mainSidebarVisible,
  setMainSidebarVisible,
  notificationsVisible,
  setNotificationsVisible,
  loading,
  notifications,
}) => {
  const { signOut } = useContext(AuthContext);
  const { id, hash } = useParams();
  const { user } = useContext(AuthContext);
  const isMobile = useIsMobileDevice();

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const notificationsRead =
    notifications?.length > 0 &&
    notifications?.reduce(
      (acc, item) => (item.readed === false ? acc + 1 : acc),
      0
    );

  //   const title = () => {
  //     switch (pathname) {
  //       case "/clientes":
  //         return "Clientes";

  //       case "/dashboard":
  //         return "Dashboard";

  //       case "/atendente":
  //         return "Configurações do Atendente";

  //       case `/atendente/passoapasso`:
  //         return "Configurações do Atendente";

  //       case `/atendente/${hash}`:
  //         return "Configurações do Atendente";

  //       case `/conta`:
  //         return "Configurações de Conta";

  //       case `/agendamento`:
  //         return "Configurações de Agendamento";

  //       case `/chat/${id}`:
  //         return "Histórico de Conversa";

  //       case "/financeiro":
  //         return "Financeiro";

  //       case "/planos":
  //         return "Planos de Assinatura";

  //       case `/planos/${hash}`:
  //         return "Planos de Assinatura";

  //       case "/tokens":
  //         return "Recarga de Tokens";

  //       case "/carrinho":
  //         return "Resumo da Compra";

  //       case "/admin/parceiros":
  //         return "Parceiros";

  //       case "/admin/financeiro":
  //         return "Financeiro";

  //       case `/admin/parceiros/${id}`:
  //         return "Clientes do Parceiro";

  //       case "/vendas/clientes":
  //         return "Clientes";

  //       case "/vendas/crm":
  //         return "CRM";

  //       case "/vendas/dashboard":
  //         return "Dashboard";

  //       case "/vendas/demo":
  //         return "Bot de Demonstração";

  //       case `/vendas/conta`:
  //         return "Configurações de Conta";

  //       case "/vendas/financeiro":
  //         return "Financeiro";

  //       default:
  //         return "Não encontrado";
  //     }
  //   };

  const handleAvatar = () => {
    if (user?.avatar) {
      return <Avatar src={user?.avatar} />;
    } else if (user?.name) {
      return <Avatar>{String(user?.name[0]).toUpperCase()}</Avatar>;
    } else if (user?.email) {
      return (
        <Avatar style={{ backgroundColor: "#EC5569" }}>
          {String(user?.email[0]).toUpperCase()}
        </Avatar>
      );
    } else {
      return <Avatar style={{ backgroundColor: "#EC5569" }} />;
    }
  };

  return (
    <AppBar
      style={{
        backgroundColor: "#ffff",
        position: "relative",
        boxShadow: "0px 0px 0px 0px",
        borderBottom: "1px solid #F6F2F3",
      }}
    >
      <Toolbar sx={{ minHeight: "75px" }}>
        <IconButton
          sx={{
            opacity: 1,
            "&:hover": {
              opacity: 0.8,
            },
          }}
          style={{ padding: 0 }}
          onClick={() => navigate("/settings")}
          // onClick={() => console.log("click")}
        >
          {handleAvatar()}
        </IconButton>
        <div style={{ flexGrow: 1, display: "flex", justifyContent: "center" }}>
          <Button
            sx={{
              opacity: 1,
              "&:hover": {
                opacity: 0.8,
              },
            }}
            disableFocusRipple
            disableTouchRipple
            onClick={() => navigate("/home")}
          >
            <img
              alt="eComLovers logo"
              src={logo}
              style={{ height: isMobile ? "35px" : "55px", flexGrow: 1 }}
            />
          </Button>
        </div>

        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={() => setMainSidebarVisible(!mainSidebarVisible)}
          edge="start"
          style={{ color: "#211E1F", marginRight: "15px" }}
        >
          <GridMenuIcon />
        </IconButton>

        <IconButton
          color="inherit"
          onClick={() => setNotificationsVisible(!notificationsVisible)}
          edge="start"
          style={{ color: "#211E1F" }}
        >
          {notificationsRead > 0 ? (
            <Typography
              color={"white"}
              fontSize={12}
              fontWeight={700}
              textAlign={"center"}
              style={{
                display: "flex",
                position: "absolute",
                border: "1px solid #EC5569",
                backgroundColor: "#EC5569",
                width: "20px",
                height: "20px",

                borderRadius: "50%",
                marginTop: -25,
                marginRight: -20,

                justifyContent: "center",
                alignItems: "center",

                padding: 2,
              }}
            >
              {notificationsRead > 9 ? "9+" : notificationsRead}
            </Typography>
          ) : null}

          <img
            src={notificationIcon}
            style={{ height: "24px" }}
            alt="notifications bell"
          />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};
