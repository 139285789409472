import {
  Avatar,
  Box,
  Button,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import React, { useContext, useEffect, useRef, useState } from "react";
import logo from "../../assets/ecomlovers-logo-transparente.svg";
import sendMessage from "../../assets/sendMessage.svg";
import systemIcon from "../../assets/systemIcon.svg";
import talentIcon from "../../assets/talentIcon.svg";

import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../providers/AuthProvider";
import useIsMobileDevice from "../../utils/mobileCheck";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import ReactInputMask from "react-input-mask";
import selectIcon from "../../assets/selectIcon.png";
import selectedIcon from "../../assets/selectedIcon.png";
import "./index.css";
import { brazilStates } from "../../utils/states";
import { getBase64, removeAfterColon } from "../../utils";
import { NewSlider } from "./components/newSlider";

export const SignUp = () => {
  const [loading, setLoading] = useState(false);
  const {
    signUp,
    handleQuestion,
    handleAnswer,
    handleAnswerFile,
    handleAnswerKnowledge,
    handleAnswerAreas,
  } = useContext(AuthContext);
  const isMobile = useIsMobileDevice();
  const [questionIndex, setQuestionIndex] = useState(0);
  const navigate = useNavigate();
  const [nextQuestion, setNextQuestion] = useState({});
  const [comment, setComment] = useState("");
  const delay = (ms) => new Promise((res) => setTimeout(res, ms));
  const [authToken, setAuthToken] = useState("");
  const hasRunEffect = useRef(false);
  const [fieldType, setFieldType] = useState("text");

  const inputRef = useRef(null);
  const [hasLoadedOnce, setHasLoadedOnce] = useState(false);
  const [tempPhone, setTempPhone] = useState("");
  const [selectKnowledge, setSelectedKnowledge] = useState([]);
  const [selectArea, setSelectedArea] = useState([]);
  const [userEmail, setUserEmail] = useState("");

  useEffect(() => {
    if (loading) {
      setHasLoadedOnce(true);
    }
  }, [loading]);

  useEffect(() => {
    if (hasLoadedOnce && !loading && inputRef.current) {
      inputRef.current.select();
    }
  }, [loading, hasLoadedOnce]);

  const handleResize = () => {
    scrollToBottom();
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const [messages, setMessages] = useState([]);

  const [questions, setQuestions] = useState([
    "Bem-vindo! Estamos felizes em tê-lo na nossa comunidade de apaixonados por eCommerce e o mundo digital.",
  ]);

  const form = useFormik({
    initialValues: {
      field: "",
    },

    onSubmit: (values) => {
      handleUserAnswer(values.field);
      form.setValues(form.initialValues);
    },
  });

  useEffect(() => {
    if (fieldType === "masked") {
      setTempPhone(form.values.field);
    }
    if (fieldType === "file" && form.values !== form.initialValues) {
      form.submitForm();
    }
  }, [form.values.field]);

  useEffect(() => {
    if (!hasRunEffect.current) {
      hasRunEffect.current = true;
      handleInitialMessage();
    }
  }, []);

  useEffect(() => {
    if (nextQuestion?.questionText) {
      handleEffect();
    } else {
      handleComment();
      setLoading(false);
    }
    if (nextQuestion?.knowledgeArea !== "talent_knowledge_area") {
      setSelectedArea(nextQuestion?.options);
    }
  }, [nextQuestion]);

  const handleEffect = async () => {
    await handleComment();
    await handleNextQuestion();
  };

  const handleInitialMessage = async () => {
    await systemMessage(questions[0]);
    setQuestionIndex(1);

    const { data } = await handleQuestion();
    setNextQuestion(data[0].attributes);
  };

  const handleComment = async () => {
    if (comment) {
      await systemMessage(comment);
    }
  };

  const handleFieldType = (question) => {
    if (question?.fieldText) {
      switch (question.fieldText) {
        default:
          return "text";
        case "birthdate":
          return "date";
        case "state":
          return "select";
        case "phone":
          return "masked";
        case "avatar":
          return "file";
      }
    } else {
      if (question?.knowledgeArea === "talent_knowledge_area") {
        return "selectKnowledge";
      } else {
        return "grade";
      }
    }
  };

  const handleNextQuestion = async () => {
    await systemMessage(nextQuestion?.questionText);
    setLoading(false);
    setQuestionIndex(nextQuestion?.questionOrder);
  };

  const systemMessage = async (text) => {
    await delay(1500);
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: text, sender: "system" },
    ]);
    scrollToBottom();
  };

  const userMessage = async (text) => {
    setMessages((prevMessages) => [
      ...prevMessages,
      { message: text, sender: "talent" },
    ]);
    scrollToBottom();
  };

  const handleUserAnswer = async (text) => {
    setLoading(true);
    if (!nextQuestion) {
      userMessage("Concluir cadastro!");
    } else if (fieldType === "grade") {
      userMessage("Respostas enviadas!");
    } else if (fieldType === "selectKnowledge") {
      userMessage("Áreas de conhecimento enviadas!");
    } else if (fieldType === "select") {
      await userMessage(brazilStates.find((item) => text === item.uf).nome);
    } else {
      if (fieldType === "file") {
        if (text === "Não") {
          await userMessage("Deixar para depois");
        } else {
          await userMessage("Imagem enviada!");
        }
      } else {
        await userMessage(text);
      }
    }
    try {
      if (!nextQuestion) {
        const { data } = await handleAnswer(questionIndex, userEmail);
        if (data?.attributes) {
          signUp(data);
        }
      } else {
        if (fieldType === "grade") {
          const { data } = await handleAnswerAreas(
            questionIndex,
            selectArea,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            setSelectedArea([]);
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else if (fieldType === "selectKnowledge") {
          const { data } = await handleAnswerKnowledge(
            questionIndex,
            selectKnowledge,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else if (fieldType === "file" && text !== "Não") {
          const { data } = await handleAnswerFile(
            questionIndex,
            text,
            authToken
          );
          if (data?.attributes) {
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        } else {
          const { data } = await handleAnswer(questionIndex, text, authToken);
          if (data?.attributes) {
            if (questionIndex === 1) {
              setUserEmail(text);
            }
            setComment(data?.attributes.message);
            setNextQuestion(data.attributes?.nextQuestion);
            setFieldType(handleFieldType(data.attributes?.nextQuestion));
            if (data?.attributes?.authToken) {
              setAuthToken(data?.attributes.authToken);
            }
          }
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const handleFileInputChange = (e) => {
    const file = e.target.files[0];

    getBase64(file).then((result) => {
      file["base64"] = result;
      form.setFieldValue(`field`, result);
    });
  };

  const MessageCard = ({ message, sender }) => {
    return (
      <div
        style={{
          display: "flex",
          width: "100%",
          flexDirection: "row",
          justifyContent: "space-between",
          alignItems: "flex-start",
          height: "auto",
          marginTop: "5px",
          marginBottom: "5px",
        }}
      >
        {sender === "system" && (
          <img
            src={systemIcon}
            alt={""}
            style={{
              width: isMobile ? "75px" : "100px",
              height: isMobile ? "75px" : "100px",
              marginRight: "10px",
            }}
          />
        )}
        <Typography
          fontWeight={500}
          sx={{
            marginBottom: "15px",
            border:
              sender === "talent" ? "1px solid #BEFCF9" : "1px solid #FFE4E5",
            backgroundColor: sender === "talent" ? "#BEFCF9" : "#FFE4E5",
            color: "black",
            borderRadius: "20px",
            padding: "25px",
            display: "flex",
            justifyContent: "flex-start",
            alignSelf: sender === "talent" ? "self-end" : "self-start",
            width: "100%",
          }}
        >
          {message}
        </Typography>
        {sender === "talent" && (
          <img
            src={talentIcon}
            alt={""}
            style={{
              width: isMobile ? "75px" : "100px",
              height: isMobile ? "75px" : "100px",
              marginLeft: "10px",
            }}
          />
        )}
      </div>
    );
  };

  const handleToggleKnowledge = (item) => {
    setSelectedKnowledge((currentSelectedKnowledge) => {
      const isSelected = currentSelectedKnowledge.includes(item.id);
      if (isSelected) {
        return currentSelectedKnowledge.filter((id) => id !== item.id);
      } else {
        return [...currentSelectedKnowledge, item.id];
      }
    });
  };

  const handleValue = (value) => {
    const knowledgeAreas = {
      dont_know: 0,
      beginner: 1,
      can_manage: 2,
      can_teach: 3,
      expert: 4,
    };

    return knowledgeAreas[value] || false;
  };

  const handleAreaLevel = (item, sliderValue) => {
    console.log(item, sliderValue);
    setSelectedArea((currentSelectedArea) => {
      const updatedArea = currentSelectedArea.reduce((acc, currentItem) => {
        if (currentItem.id === item.id) {
          acc.push({ ...currentItem, level: sliderValue });
        } else {
          acc.push(currentItem);
        }
        return acc;
      }, []);
      console.log("Updated Area:", updatedArea);
      return updatedArea;
    });
  };

  return (
    <Box
      style={{
        display: "flex",
        backgroundColor: "white",
        width: "100%",
        height: "100vh",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      <Box
        style={{
          display: "flex",
          backgroundColor: "white",

          width: isMobile ? null : "50%",
          height: isMobile ? "100%" : "100vh",
          paddingRight: isMobile ? "20px" : "0px",
          paddingLeft: isMobile ? "20px" : "0px",
          justifyContent: "flex-start",
          alignItems: "center",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <img
            src={logo}
            alt={""}
            style={{
              width: isMobile ? "100px" : "200px",
              marginBottom: "25px",
              marginTop: "25px",
            }}
          />
          <div style={{ border: "1px solid #F6F2F3", width: "100%" }} />
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              height: "80%",
              flexDirection: "column",
              overflowY: "scroll",
              scrollbarWidth: "none",
            }}
          >
            {messages?.length > 0 &&
              messages.map((item, index) => (
                <MessageCard
                  key={index}
                  message={item.message}
                  sender={item.sender}
                />
              ))}
            {!loading && fieldType === "grade" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                  justifyContent: "space-around",
                  marginTop: "25px",
                  marginBottom: "25px",
                  width: "100%",
                }}
              >
                {nextQuestion?.options.map((item, index) => (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                      border: "1px solid #E3E3E3",
                      borderRadius: "10px",
                      padding: isMobile ? "20px 0px 20px 20px" : "20px",
                      background:
                        "radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%)",
                    }}
                    key={index}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "100px",
                        justifyContent: "center",
                      }}
                    >
                      <Avatar />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        width: "100%",
                      }}
                    >
                      <Typography style={{ width: "75%" }}>
                        {item.optionText}
                      </Typography>
                      <div
                        style={{
                          display: "flex",
                          width: "100%",
                        }}
                      >
                        <NewSlider
                          key={index}
                          item={{
                            ...item,
                            level: handleValue(item?.level) || 0,
                          }}
                          handleAreaLevel={handleAreaLevel}
                        />
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
            {!loading && fieldType === "selectKnowledge" && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "wrap",
                  gap: "6px",
                  justifyContent: "space-around",
                  marginTop: "25px",
                  marginBottom: "25px",
                }}
              >
                {nextQuestion?.options.map((item, index) => (
                  <Button
                    variant="outlined"
                    color="secondary"
                    sx={{
                      width: "49%",
                      borderRadius: "10px",
                      fontWeight: "500",
                      color: selectKnowledge.includes(item.id)
                        ? "#FFFFFF"
                        : "#211E1F",
                      borderColor: selectKnowledge.includes(item.id)
                        ? "#BEFCF9"
                        : "#BFB7B8",
                      padding: "18px 10px 18px 10px",
                      backgroundColor: selectKnowledge.includes(item.id)
                        ? "#57BAB8"
                        : null,

                      ":hover": {
                        color: selectKnowledge.includes(item.id)
                          ? "#FFFFFF"
                          : "#57BAB8",

                        backgroundColor: selectKnowledge.includes(item.id)
                          ? "#57BAB8"
                          : null,

                        opacity: selectKnowledge.includes(item.id)
                          ? "0.75"
                          : "1",
                      },
                    }}
                    onClick={() => handleToggleKnowledge(item)}
                  >
                    <div
                      style={{
                        display: "flex",
                        width: "20%",
                        justifyContent: "center",
                      }}
                    >
                      <img
                        src={
                          selectKnowledge.includes(item.id)
                            ? selectedIcon
                            : selectIcon
                        }
                        alt="edit icon"
                      />
                    </div>
                    <div style={{ display: "flex", width: "80%" }}>
                      {removeAfterColon(item.optionText)}
                    </div>
                  </Button>
                ))}
              </div>
            )}
            <div ref={messagesEndRef} />
          </div>
          <div
            style={{
              border: "1px solid #F6F2F3",
              width: "100%",
              marginBottom: "25px",
            }}
          />
          <form
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginBottom: "15px",
              justifyContent:
                fieldType === "selectKnowledge" || fieldType === "grade"
                  ? "flex-end"
                  : "center",
            }}
            action="javascript:void(0);"
            onSubmit={(e) => {
              e.preventDefault();
              form.submitForm();
            }}
          >
            {fieldType === "text" && (
              <TextField
                inputRef={inputRef}
                label={"Digite aqui sua resposta"}
                autoCapitalize={"none"}
                value={form.values.field}
                onChange={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "85%" }}
                disabled={loading}
              />
            )}

            {fieldType === "date" && (
              <DatePicker
                label={"Digite aqui sua resposta"}
                autoCapitalize={"none"}
                onChange={(e) => {
                  form.setFieldValue("field", moment(e).format("DD/MM/YYYY"));
                }}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "85%" }}
                disabled={loading}
                slotProps={{
                  textField: {
                    color: "secondary",
                    style: { width: "85%" },
                  },
                }}
              />
            )}

            {fieldType === "select" && (
              <TextField
                label={"Insira aqui sua resposta"}
                autoCapitalize={"none"}
                value={form.values.field}
                onChange={(e) => {
                  form.setFieldValue("field", e.target.value);
                }}
                onBlur={() => {
                  form.setFieldTouched("field", true);
                }}
                color="secondary"
                variant="outlined"
                style={{ width: "85%" }}
                disabled={loading}
                select
              >
                {brazilStates.map((item, index) => (
                  <MenuItem key={index} value={item.uf}>
                    {item.nome}
                  </MenuItem>
                ))}
              </TextField>
            )}

            {fieldType === "masked" && (
              <ReactInputMask
                mask={
                  tempPhone.replace(/\s/g, "").length > 14
                    ? "+99 99 99999-9999"
                    : "+99 99 9999-99999"
                }
                value={form.values.field}
                onChange={(e) => form.setFieldValue(`field`, e.target.value)}
                onBlur={() => {
                  form.setFieldTouched(`field`, true);
                }}
                maskChar={""}
                disabled={loading}
              >
                {() => (
                  <TextField
                    inputRef={inputRef}
                    label={"Digite aqui sua resposta"}
                    autoCapitalize={"none"}
                    value={form.values.field}
                    color="secondary"
                    variant="outlined"
                    style={{ width: "85%" }}
                    disabled={loading}
                  />
                )}
              </ReactInputMask>
            )}

            {fieldType === "file" && (
              <div
                style={{
                  width: "55%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                }}
              >
                <Button
                  variant="outlined"
                  color="secondary"
                  style={{ width: "95%" }}
                  disabled={loading}
                  component={"label"}
                >
                  <input
                    hidden
                    type="file"
                    id="fupload"
                    name="fupload"
                    accept=".jpeg, .png, .jpg"
                    onChange={handleFileInputChange}
                  />
                  Inserir foto de perfil
                </Button>
              </div>
            )}

            {!nextQuestion ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "100px",
                    textTransform: "none",
                  }}
                  onClick={() => form.submitForm()}
                  disabled={loading}
                  variant="contained"
                >
                  <Typography fontSize={16} fontWeight={700}>
                    Terminar
                  </Typography>
                </Button>
              </div>
            ) : fieldType === "file" ? (
              <Button
                onClick={() => form.setFieldValue("field", "Não")}
                variant="outlined"
                disabled={loading}
              >
                Deixar para depois
              </Button>
            ) : fieldType === "selectKnowledge" || fieldType === "grade" ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Button
                  style={{
                    width: "100%",
                    borderRadius: "100px",
                    textTransform: "none",
                  }}
                  onClick={() => form.submitForm()}
                  disabled={loading}
                  variant="contained"
                >
                  <Typography fontSize={16} fontWeight={700}>
                    Próximo
                  </Typography>
                </Button>
              </div>
            ) : (
              <div
                style={{
                  width: "15%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <IconButton
                  style={{ padding: 0 }}
                  onClick={() => form.submitForm()}
                  disabled={loading}
                >
                  <img
                    src={sendMessage}
                    alt={""}
                    style={{
                      width: "50px",
                      height: "50px",
                      opacity: loading ? "20%" : "100%",
                    }}
                  />
                </IconButton>
              </div>
            )}
          </form>
        </div>
      </Box>
    </Box>
  );
};
