import { useContext, useEffect, useState } from "react";
import { AuthorizedLayout } from "../../components/AuthorizedLayout";
import {
  Box,
  Button,
  Fab,
  Grow,
  Icon,
  IconButton,
  Input,
  Rating,
  Typography,
} from "@mui/material";
import useIsMobileDevice from "../../utils/mobileCheck";
import { InputWithLabel } from "../../components/InputWithLabel";
import { useFormik } from "formik";
import profileCharacter from "../../assets/astronaut 3.svg";
import addPhoto from "../../assets/addPhoto.svg";
import { AuthContext } from "../../providers/AuthProvider";
import api from "../../utils/api";
import unmask from "../../utils/unmask";
import { toast } from "react-toastify";
import moment from "moment";
import heart from "../../assets/heartIcon.svg";
import heartInactive from "../../assets/heartIconInactive.svg";
import info from "../../assets/info.svg";
import { useNavigate } from "react-router-dom";
import { Loader } from "../../components/Loader";

import astrounautStandingThinking from "../../assets/posesPNG/astronout-standing-thinking.png";
import { ExpandLess, ExpandMore, WhatsApp } from "@mui/icons-material";

export const FAQ = () => {
  const isMobile = useIsMobileDevice();
  const navigate = useNavigate();

  const QuestionCard = ({ question, answer }) => {
    const [open, setOpen] = useState(false);

    return (
      <Button
        style={{ textTransform: "none", padding: 0, width: "100%" }}
        color={"inherit"}
        onClick={() => setOpen(!open)}
      >
        <Box
          sx={{
            background:
              "radial-gradient(65.17% 65.17% at 50% 50%, #FFFFFF 80.5%, #F9F9F9 100%)",
            borderRadius: "10px",
            border: "1px solid #F6F2F3",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            boxShadow: "0px 4px 10px 0px #00000033",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "18px",
            }}
          >
            <Typography
              fontSize={"14px"}
              lineHeight={"19.2px"}
              fontWeight={700}
              align="left"
              color={"#211E1F"}
            >
              {question}
            </Typography>
            {open ? (
              <ExpandLess style={{ color: "#211E1F", padding: "8px" }} />
            ) : (
              <ExpandMore style={{ color: "#211E1F", padding: "8px" }} />
            )}
          </div>
          <Grow in={open} style={{ transformOrigin: "0 0 0" }}>
            <Box
              sx={{
                padding: "18px",
                background: "#F6F2F3",
                borderRadius: "0px 0px 10px 10px",
                border: "1px solid #F6F2F3",
                borderTop: "1px solid #BFB7B8",
                display: open ? "flex" : "none",
                flexDirection: "column",
                justifyContent: "space-evenly",
              }}
            >
              <Typography
                fontSize={"14px"}
                lineHeight={"19.2px"}
                fontWeight={300}
                align="left"
                color={"#211E1F"}
              >
                {answer}
              </Typography>
            </Box>
          </Grow>
        </Box>
      </Button>
    );
  };

  return (
    <AuthorizedLayout>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            width: isMobile ? "100%" : "800px",
            marginTop: "30px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",

              width: "100%",
              flexDirection: "column",
            }}
          >
            <Box
              sx={{
                backgroundColor: "#BEFCF9",
                border: "1px solid #57BAB8",
                borderRadius: "10px",
                width: "100%",
                aspectRatio: 350 / 115,
                marginBottom: "28px",
                display: "flex",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "60%",
                  padding: "24px",
                }}
              >
                <Typography
                  fontSize={"18px"}
                  lineHeight={"21.6px"}
                  fontWeight={700}
                  align="left"
                  color={"#211E1F"}
                >
                  Perguntas Frequentes
                </Typography>
                <Typography
                  fontSize={"14px"}
                  lineHeight={"16.8px"}
                  fontWeight={500}
                  align="left"
                  color={"#211E1F"}
                  marginTop={"12px"}
                >
                  Donec sed odio dui. Cum sociis natoque penatibus et magnis dis
                </Typography>
              </Box>

              <div
                style={{
                  position: "relative",
                  height: "100%",
                  width: "40%",
                }}
              >
                <img
                  src={astrounautStandingThinking}
                  style={{
                    position: "absolute",
                    height: "110%",
                    maxWidth: "100%",
                    objectFit: "contain",
                    top: "40%",
                    left: "45%",
                    transform: "translate(-50%, -50%)",
                  }}
                />
              </div>
            </Box>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "wrap",
              gap: "6px",
              justifyContent: "space-around",
              paddingRight: "6px",
              paddingLeft: "6px",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginBottom: "40px",
                gap: "15px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <QuestionCard
                question={"Descrição de uma pergunta frequente"}
                answer={
                  "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare vel eu leo."
                }
              />
              <QuestionCard
                question={"Descrição de uma pergunta frequente"}
                answer={
                  "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare vel eu leo."
                }
              />
              <QuestionCard
                question={"Descrição de uma pergunta frequente"}
                answer={
                  "Vivamus sagittis lacus vel augue laoreet rutrum faucibus dolor auctor. Nullam quis risus eget urna mollis ornare vel eu leo."
                }
              />
            </Box>
          </div>
        </div>
        <Button
          sx={{
            bottom: "20px",
            right: "20px",
            position: "fixed",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            borderRadius: "50%",
            backgroundColor: "#25d366",
            minWidth: 0,
            padding: isMobile ? 1 : 0.5,

            "&:hover": {
              opacity: "75%",
              backgroundColor: "#25d366",
            },
          }}
          href="https://wa.me/5511952939526"
          target="_blank"
        >
          <WhatsApp
            sx={{
              color: "white",
              width: isMobile ? "50px" : "25px",
              height: isMobile ? "50px" : "25px",
            }}
          />
        </Button>
      </div>
    </AuthorizedLayout>
  );
};
